import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DialogContent, Typography } from "@mui/material";
import { MdInput } from "react-icons/md";
import { size } from "lodash";
// import { jobSourcesSelectors } from "features/Taxonomies/taxonomiesSlice";

import { SelectFormField } from "common/Fields";
import { DialogForm } from "common/Dialog";

import { getDivisionDataSelector, getJobDataSelector, patchJob, fetchDivision, getMemberDataSelector } from "../jobSlice";
import IconTextItem from "common/IconTextItem";
import { RiEdit2Fill } from "react-icons/ri";
import { parseJobSource } from "../utils";
import MemberSelector from "features/Member/components/MemberSelector";

const JobSource = ({color, ...props}) => {
  const dispatch = useDispatch();
  const division = useSelector(getDivisionDataSelector);
  const divisionMember = useSelector(getMemberDataSelector);
  const job = useSelector(getJobDataSelector);
  const [open, setOpen] = useState(false);
  // const allJobSourceOptions = useSelector(jobSourcesSelectors.selectAll);
  const _jobSourceOptions =  useSelector(
    (state) => state.auth.user.data._processed.job_source_options
  );
  // if(_jobSourceOptions <= 1){
  //   return null;
  // }


  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = (data, setSubmitting) => {
    const params = {
      field_job_source: [{ value: data.field_job_source }],
      field_member_owned: null,
    }
    if(data.field_job_source === 'member'){
      params.field_member_owned = [{ target_id: data.field_member_owned.nid }];
    }
    setSubmitting(true);

    dispatch(
      patchJob({
        id: job.nid,
        params: params,
        job,
      })
    ).then((resp) => {
    //  Refresh the Job Division
      dispatch(
        fetchDivision(division.title)
      ).then((resp) => {
          setSubmitting(false);
          onClose();
        });
    });

  };

  const onClose = () => {
    setOpen(false);
  };

  let jobSourceOptions = parseJobSource(_jobSourceOptions, job.field_phoenix_client, true);
  let jobSource = jobSourceOptions.find((source) => source.value === job.field_job_source);

//  Omit the client job source option if the client is Helix and the helix option exists
  if(job.field_phoenix_client
      && job.field_phoenix_client.title.indexOf('Helix') > -1
      && size(jobSourceOptions.filter((option) => option.value === 'helix')) > 0){
    jobSourceOptions = jobSourceOptions.filter((option) => option.value !== 'client');
  }
  const editEnabled = division?._processed?.update_access
                      && division?._processed?.edit_job_source
                      && jobSourceOptions.length > 1;

  let className = "flex";
  if(!props?.newLine){
    className += " justify-end";
  }
  if(editEnabled){
    className += " cursor-pointer";
  }
  else{
    className += " cursor-default";
  }

  const textColor = (color === 'primaryInverse' ? 'text-white' : '');
  const iconColor = (color === 'primaryInverse' ? 'text-gray' : 'text-gray-dark');
  const jobSourceInfo = (
    <Typography variant="body1" className={`flex items-center ${textColor} ${!props?.newLine ? 'mb-2' : ''}`}>
      {jobSource?.label}
      {editEnabled && <RiEdit2Fill className={`ml-2 ${iconColor}`} />}
    </Typography>
  );

  let defaultMember = {};
  if(size(job.field_member_owned)){
    defaultMember = {
      nid: job.field_member_owned.nid,
      name: job.field_member_owned.title,
    }
  }
  else if(divisionMember){
    defaultMember = {
      nid: divisionMember.nid,
      name: divisionMember.title,
    }
  }

  return (
    <div {...props}>
      <div
        className={className}
        onClick={(editEnabled ? handleOpen : null)}
      >
        <IconTextItem icon={<MdInput className={iconColor}/>}>
          <Typography
            variant="subtitle2"
            className="flex uppercase items-center mr-3"
            color={color}
          >
            Job Source
          </Typography>
          {props?.newLine && jobSourceInfo }
        </IconTextItem>
        {!props?.newLine && jobSourceInfo }
      </div>
      <DialogForm
        open={open}
        onClose={onClose}
        maxWidth="xs"
        title="Change Job Source"
        initialValues={{
          field_job_source: job.field_job_source,
          field_member_owned: defaultMember,
        }}
        disablePadding
        onSubmit={handleSubmit}
        directSubmit
        overrideDirty
      >
        {({ values, errors, isSubmitting, validateField, setFieldValue }) => (
          <DialogContent>
            <SelectFormField
              fullWidth
              name="field_job_source"
              label="Job Source"
              size="small"
              variant="outlined"
              disabled={isSubmitting}
              options={jobSourceOptions}
            />
            {values.field_job_source === 'member' &&
              <MemberSelector
                name="field_member_owned"
                disabled={isSubmitting}
                phxClientNid={job.field_phoenix_client.nid}
                required
              />
            }
          </DialogContent>
        )}
      </DialogForm>
    </div>
  );
};

JobSource.propTypes = {};

export default JobSource;
