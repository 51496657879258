import {
  createSlice,
  createEntityAdapter,
  createAsyncThunk,
  createDraftSafeSelector,
} from "@reduxjs/toolkit";
import axios from "axios";

import { tokenConfig } from "../../actions/authActions";
import config from "../../config";
import { setAlert } from "features/Alert/alertSlice";
import { formatTempUserForListing } from "../Users/utils";
import getQueryParams from "utility/getQueryParams";
import { generateTempUUID } from "utility";

const namespace = "tempUsers";

export const fetchTempUsers = createAsyncThunk(
  `${namespace}/fetchTempUsers`,
  async ({ memberNid, params }, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();
      const queryParams = getQueryParams(params);

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/temporary-users/${memberNid}${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );
      return {
        users: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchTempUser = createAsyncThunk(
  `${namespace}/fetchTempUser`,
  async (nid, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(`${config.api_url}/node/${nid}`, {
        ...tokenConfig(getState),
        cancelToken: source.token,
      });


      return formatTempUserForListing(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const postTempUser = createAsyncThunk(
  `${namespace}/postTempUser`,
  async ({user, params}, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${config.api_url}/node`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/temporary_user`,
            },
          },
          ...params,
        },
        tokenConfig(getState)
      );

      return formatTempUserForListing(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error adding user: ${error.response.data?.message}`,
        })
      );

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const patchTempUser = createAsyncThunk(
  `${namespace}/patchTempUser`,
  async ( {user, params}, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/node/${user.nid}`,
        {
          _links: {
            type: {
              href: `${config.api_url}/rest/type/node/temporary_user`,
            },
          },
          ...params,
        },
        tokenConfig(getState)
      );

      return formatTempUserForListing(response.data);
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error adding user: ${error.response.data?.message}`,
        })
      );

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchMergeUsers = createAsyncThunk(
  `${namespace}/fetchMergeUsers`,
  async ({ memberNid, params }, { getState, signal, rejectWithValue }) => {
    try {
      const source = axios.CancelToken.source();
      const queryParams = getQueryParams({
        'filter[status][0]': 1,
        ...params,
      });

      signal.addEventListener("abort", () => {
        source.cancel();
      });

      const response = await axios.get(
        `${config.api_url}/rest/temporary-users/${memberNid}/1${queryParams}`,
        {
          ...tokenConfig(getState),
          cancelToken: source.token,
        }
      );
      return {
        users: response.data.data,
        pagination: response.data.pagination,
      };
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const archiveTempUsers = createAsyncThunk(
  `${namespace}/archiveTempUsers`,
  async ( {params}, { getState, dispatch, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${config.api_url}/rest/temporary-user/archive`,
        params,
        tokenConfig(getState)
      );

      return response.data;
    } catch (err) {
      let error = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      dispatch(
        setAlert({
          show: true,
          kind: "negative",
          msg: `Error archiving users: ${error.response.data?.message}`,
        })
      );

      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

const tempUsersAdapter = createEntityAdapter({
  selectId: (user) => user.nid,
});

const tempUsersSlice = createSlice({
  name: namespace,
  initialState: tempUsersAdapter.getInitialState({
    refresh: '',
    loading: false,
    error: null,
    pagination: { count: 0, current_page: 0, total_pages: 0 },
    currentTempUser: {
      loading: false,
      error: null,
      data: {},
    },
    mergeUsers: tempUsersAdapter.getInitialState({
      loading: false,
      error: null,
      pagination: { count: 0, current_page: 0, total_pages: 0 },
    }),
  }),
  reducers: {
    setAllTempUsers: tempUsersAdapter.setAll,
    addOneTempUser: tempUsersAdapter.addOne,
    refreshTempUsers: (state) => {
      state.refresh = generateTempUUID();
    },
  },
  extraReducers: {
    [fetchTempUsers.pending](state) {
      state.loading = true;
      state.error = null;
    },
    [fetchTempUsers.fulfilled](state, { payload: {users, pagination} }) {
      tempUsersAdapter.setAll(state, users);
      state.loading = false;
      state.pagination = pagination;
      state.error = null;
    },
    [fetchTempUsers.rejected](state, action) {
      state.loading = false;
      if (action.payload) {
        state.error = action.payload.message;
      } else {
        state.error = action.error.message;
      }
    },
    [fetchTempUser.pending](state) {
      state.currentTempUser.error = null;
      state.currentTempUser.loading = true;
    },
    [fetchTempUser.fulfilled](state, { payload }) {
      state.currentTempUser.loading = false;
      state.currentTempUser.data = payload;
    },
    [fetchTempUser.rejected](state, action) {
      if (!action.meta.aborted) {
        state.currentTempUser.loading = false;
        if (action.payload) {
          state.currentTempUser.error = action.payload.message;
        } else {
          state.currentTempUser.error = action.error.message;
        }
      }
    },
    [postTempUser.pending](state) {},
    [postTempUser.fulfilled](state, { payload: user }) {
      state.currentTempUser = user;
    },
    [postTempUser.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.currentTempUser.error = action.payload.message;
        } else {
          state.currentTempUser.error = action.error.message;
        }
      }
    },
    [patchTempUser.pending](state) {},
    [patchTempUser.fulfilled](state, { payload: user }) {
      state.currentTempUser = user;
    },
    [patchTempUser.rejected](state, action) {
      if (!action.meta.aborted) {
        if (action.payload) {
          state.currentTempUser.error = action.payload.message;
        } else {
          state.currentTempUser.error = action.error.message;
        }
      }
    },
    [fetchMergeUsers.pending](state) {
      state.mergeUsers.loading = true;
      state.mergeUsers.error = null;
    },
    [fetchMergeUsers.fulfilled](state, { payload: {users, pagination} }) {
      tempUsersAdapter.setAll(state.mergeUsers, users);
      state.mergeUsers.loading = false;
      state.mergeUsers.pagination = pagination;
      state.mergeUsers.error = null;
    },
    [fetchMergeUsers.rejected](state, action) {
      state.mergeUsers.loading = false;
      if (action.payload) {
        state.mergeUsers.error = action.payload.message;
      } else {
        state.mergeUsers.error = action.error.message;
      }
    },
  },
});

export const tempUsersSelectors = tempUsersAdapter.getSelectors((state) => state.tempUsers);
export const mergeUsersSelectors = tempUsersAdapter.getSelectors((state) => state.tempUsers.mergeUsers);

// Custom selectors
const selectSelf = (state) => state;
export const getTempUsersDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.tempUsers.data
);

export const getTempUsersLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.tempUsers.loading
);

export const getTempUsersRefreshSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.tempUsers.refresh
);

export const getTempUsersErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.tempUsers.error
);

export const getTempUsersPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.tempUsers.pagination
);

export const getTempUserDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.tempUsers.currentTempUser.data
);

export const getTempUserLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.tempUsers.currentTempUser.loading
);

export const getTempUserErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.tempUsers.currentTempUser.error
);

export const getMergeUsersDataSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.tempUsers.mergeUsers.data
);

export const getMergeUsersLoadingSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.tempUsers.mergeUsers.loading
);

export const getMergeUsersErrorSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.tempUsers.mergeUsers.error
);

export const getMergeUsersPaginationSelector = createDraftSafeSelector(
  selectSelf,
  (state) => state.tempUsers.mergeUsers.pagination
);


export const { setAllTempUsers, addOneTempUser, refreshTempUsers } = tempUsersSlice.actions;

export default tempUsersSlice.reducer;
