import React, { useEffect, useState, useCallback, } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import {
  Typography,
  Card,
} from "@mui/material";
import { DefaultLoader } from "common/Loader";
import Empty from "@phx/common/Empty";
import {
  fetchTimeClock,
  postTimeClock,
  getTimeClockLoadingSelector,
  getTimeClockErrorSelector,
  getTimeClockSelector,
  refreshUserTimeCards,
} from "./timeClockSlice";
import { parseTimeClockParams, getParamTime} from "./utils";
import "./styles/TimeClock.scss";
import TimeClockCustomer from './components/TimeClockCustomer';
import TimeClockChange from './components/TimeClockChange';
import TimeClockUser, { timeClockValidateUser } from './components/TimeClockUser';
import TimeClockAdminButtons from "./components/TimeClockAdminButtons";
import TimeClockTimer, { timeClockValidateTimer } from './components/TimeClockTimer';
import TimeClockSignature, { timeClockValidateSignature } from './components/TimeClockSignature';
import TimeClockLaborType, { TimeClockLaborTypeInline, timeClockValidateLaborType } from './components/TimeClockLaborType';
import TimeClockServiceActivity, { TimeClockServiceActivityInline, timeClockValidateServiceActivity } from './components/TimeClockServiceActivity';
import TimeClockTravel from "./components/TimeClockTravel";
import TimeClockLog from "./components/TimeClockLog";
import Layout from "common/Layout";
import { BsClockHistory } from "react-icons/bs";
import { FaUserCheck } from "react-icons/fa";
import PunchClockIcon from '@mui/icons-material/PunchClock';
import { Formik } from "formik";
import Alert from "common/Alert";
import moment from "moment-timezone";
import { last, size, isNull } from "lodash";
// import constants from "components/constants";
import { getIsAuthenticatedSelector, updateAuthUser } from "features/Auth/authSlice";
// import DailySheet from "features/DailySheet";
// import ServiceAddEdit from "features/Service/forms/ServiceAddEdit";
import {
  getDailySheetLoadingSelector,
} from "features/DailySheet/dailySheetSlice";
import { generateTempUUID } from "utility";
import { getDivisionTypeIndicator } from "features/Job/utils";
import CollapsibleIcon from "common/CollapsibleIcon";


const TimeClock = ({
  divisionHash,
  serviceHash,
  setServiceHash,
  newTimeCard,
  timeCardOnly,
  defaultUser,
  closeDialog,
  showDivisionInfo=false,
}) => {
  const dispatch = useDispatch();
  const { hash, service_hash } = useParams();
  const history = useHistory();
  const isAuthenticated = useSelector(getIsAuthenticatedSelector);
  const authUser = useSelector((state) => state.auth.user.data);
  // const currentUser = useSelector((state) => state.auth.user.data);
  const timeClock = useSelector(getTimeClockSelector);
  const loading = useSelector(getTimeClockLoadingSelector);
  const error = useSelector(getTimeClockErrorSelector);
  const loadingSheet = useSelector(getDailySheetLoadingSelector);
  const [message, setMessage] = useState(null);
  const [subMessage, setSubMessage] = useState(null);
  const [refresh, setRefresh] = useState("");

  useEffect(() => {
    if(divisionHash){
      if(newTimeCard){
        getNewTimeClock();
      }
      else{
        getTimeClock(divisionHash);
      }
    }
    else if(hash){
      if(service_hash){
        localStorage.setItem("time_clock_service", service_hash);
        localStorage.removeItem("time_clock_temp_user");
        localStorage.removeItem("time_clock_user_account");
        history.push(`/time-clock/${hash}`);
      }
      getTimeClock(hash);
    }
  }, [divisionHash, newTimeCard, hash, refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  const refreshTimeClock = () => {
    setRefresh(generateTempUUID());
  };

  const getTimeClock = (hash, newCard, reset) => {
    const params = {
      ...parseTimeClockParams({service: serviceHash})
    };
    if(newCard){
      params.new_card = true;
      // if(isAuthenticated){
      //   params.user_account = currentUser.uid;
      // }
      if(defaultUser){
        params.user_account = defaultUser;
      }
    }
    dispatch(fetchTimeClock({hash, manualService: Boolean(serviceHash), params}))
      .then((resp) => {
        if(!reset && resp?.error && (resp?.payload?.message === 'Invalid Service' || resp?.payload?.message === 'Invalid Temp User')){
          if(resp?.payload?.message === 'Invalid Service'){
            localStorage.removeItem("time_clock_service");
          }
          if(resp?.payload?.message === 'Invalid Temp User'){
            localStorage.removeItem("time_clock_temp_user");
          }
          getTimeClock(hash, newCard, true);
        }
      })
  };

  const getNewTimeClock = () => {
    localStorage.removeItem("time_clock_service");
    // if(isAuthenticated){
      localStorage.removeItem("time_clock_temp_user");
      localStorage.removeItem("time_clock_user_account");
    // }
    getTimeClock((divisionHash ? divisionHash : hash), true);
  };

  const changeTimeCard = (uid, tempUserHash) => {
    localStorage.removeItem("time_clock_service");
    if(tempUserHash){
      localStorage.setItem("time_clock_temp_user", tempUserHash);
    }
    if(uid){
      localStorage.setItem("time_clock_user_account", uid);
    }
    getTimeClock(divisionHash ? divisionHash : hash);
  };

  const onSubmit = useCallback( (data, { setSubmitting, setValues }) => {
    if(timeClock.read_only){
      console.error("Time Clock is in Read Only mode");
      return;
    }
    setSubmitting(true);

    const serviceDate = data.service_date;
    const params = {
      ...parseTimeClockParams({service: serviceHash}),
      service_date: serviceDate.format(`YYYY-MM-DD`),
      service_start: '',
      service_end: '',
      location: data.location,
    };

    if(data.service_start){
      const startTime = getParamTime(data, "service_start");
      params.service_start = startTime;
      data.service_start = startTime; // this helps get correct times later
    }

    if(data.service_end){
      const endTime = getParamTime(data, "service_end");
      params.service_end = endTime;
      data.service_end = endTime; // this helps get correct times later
    }

    if(data.select_time_card !== ''){
      params.select_time_card =  data.select_time_card;
    }

    if(data.service_type !== ''){
      params.service_type =  data.service_type;
    }

    if(data.service_activity_selector !== '' && data.service_activity_selector !== 'Other'){
      params.service_activity =  data.service_activity_selector;
    }
    else if(data.service_activity !== ''){
      params.service_activity =  data.service_activity;
    }

    if(data.selected_user && data.selected_user !== ''){
      params.selected_user =  data.selected_user;
    }
    else if(data.temp_user_first_name){
      let lName = timeClock?.temp_user_last_name && timeClock.temp_user_last_name.trim() !== '' ? ' ' + timeClock.temp_user_last_name.trim() : '';
      const timeClockUserName = `${timeClock?.temp_user_first_name ? timeClock.temp_user_first_name.trim() : ''}${lName}`;
      lName = data?.temp_user_last_name && data.temp_user_last_name.trim() !== '' ? ' ' + data.temp_user_last_name.trim() : '';
      const newUserName = `${data.temp_user_first_name.trim()}${lName}`;
      if(!params.temp_user || newUserName !== timeClockUserName){
        params.temp_user_first_name =  data.temp_user_first_name.trim();
        params.temp_user_last_name =  data.temp_user_last_name.trim();
        params.temp_user = null;
      }
    }

    if(data.temp_user_email !== ''){
      params.temp_user_email =  data.temp_user_email;
    }

    if(!isNull(data.service_travel)){
      params.service_travel = data.service_travel;
    }

    if(data.submitting_button === 'break_start'){
      params.service_break_start = getParamTime(data, "service_break_start", size(data.service_breaks));
    }
    else if(data.submitting_button === 'break_end'){
      params.service_break_hash = last(timeClock.service_breaks).hash;
      params.service_break_end = getParamTime(data, "service_break_end", size(data.service_breaks) - 1); // Check against start time
    }
    else if(data.service_breaks){
      params.service_breaks = data.service_breaks.map((breakTime, i) => {
        const _startTime = getParamTime(data, "service_break_start", i);
        data.service_breaks[i] = {...data.service_breaks[i], start: _startTime}; // this helps get correct times later
        const _endTime = (breakTime.end ? getParamTime(data, "service_break_end", i) : '');
        data.service_breaks[i] = {...data.service_breaks[i], end: _endTime}; // this helps get correct times later

        return {
          'start': _startTime,
          'end': _endTime,
          'hash': breakTime.hash,
        };
      });
    }

    if(data.clock_in_photo && !data.clock_in_photo_hash){
      params.clock_in_photo = data.clock_in_photo;
    }

    if(data.clock_out_photo && !data.clock_out_photo_hash){
      params.clock_out_photo = data.clock_out_photo;
    }

    if(data.submitting_button === 'signature'){
      params.signature = data.signature;
      params.signature_name = data.signature_name;
      params.signature_date = data.signature_date.format(`YYYY-MM-DD`);
    }

    if(size(data.log_entries)){
      params.log_entries = data.log_entries;
    }

    const _hash = (divisionHash ? divisionHash : hash);
    dispatch(postTimeClock({
      hash: _hash,
      manualService: Boolean(serviceHash),
      params
    }))
      .then((resp) => {
        setSubmitting(false);
        if(newTimeCard && setServiceHash){
          setServiceHash(resp.payload.service);
        }
        if(resp.payload?.service_breaks){
          setValues(setInitialValues(resp.payload));
        }

      //  Update the Auth user if this is their card and it's open or they have just closed it.
        if(isAuthenticated && Number(resp.payload?.user_id) === Number(authUser.uid)){
          let updateUser = false;
          let openCard = null;
        //  Not a complete time card
          if(resp.payload?.service_status	!== 'complete'){
            updateUser = true;
            openCard = {
              division_hash: _hash,
              service_hash: resp.payload?.service,
            };
          }
        //  The time card was just now completed
          else if(timeClock.service_status !== 'complete'){
            updateUser = true;
          }
          if(updateUser){
            dispatch(updateAuthUser({
              ...authUser,
              _processed: {
                ...authUser._processed,
                open_time_card: openCard,
              }
            }));
          //  If clearing the open card, refresh the user time cards from here since the open time card indicator will just disappear
            if(!openCard){
               dispatch(refreshUserTimeCards());
            }
          }
        }
      });
  }, [timeClock]); // eslint-disable-line react-hooks/exhaustive-deps

  const setInitialValues = (values) => {
    return {
      temp_user_first_name: values?.temp_user_first_name ? values.temp_user_first_name : '',
      temp_user_last_name: values?.temp_user_last_name ? values.temp_user_last_name : '',
      selected_user: values?.user_id ? 'uid-' + values.user_id : '',
      service_date: values?.service_date ? moment(values.service_date) : moment(),
      service_start: values?.service_start ? moment.parseZone(values.service_start) : null,
      service_end: values?.service_end ? moment.parseZone(values.service_end) : null,
      service_type: values?.service_type ? values.service_type : '',
      service_activity_selector: values?.service_activity ?
          (!values.service_activity_other ? values.service_activity : 'Other')
        : '',
      service_activity: values?.service_activity ? values.service_activity : '',
      temp_user_email: '',
      service_breaks: values?.service_breaks ? [...values.service_breaks] : null,
      clock_in_photo: values?.service_clock_in_photo ? values.service_clock_in_photo : null,
      clock_in_photo_hash: values?.service_clock_in_photo_hash ? values.service_clock_in_photo_hash : null,
      clock_out_photo: values?.service_clock_out_photo ? values.service_clock_out_photo : null,
      clock_out_photo_hash: values?.service_clock_out_photo_hash ? values.service_clock_out_photo_hash : null,
      service_travel: values?.service_travel ? values.service_travel : null,
      signature: null,
      signature_name: '',
      signature_date: moment(),
      submitting_button: null,
      select_time_card: '',
      selecting_time_card: false,
      log_entries: [],
      warnings: values?.warnings && size(values.warnings) ? values.warnings : null,
      location: null,
    };
  }

  const showAdminHeading = timeClock?.division_time_clock_manager && Boolean(timeClock?.service_nid);
  let adminButtons;
  if(showAdminHeading){
    adminButtons = (
      <TimeClockAdminButtons
        nid={timeClock.service_nid}
        hash={timeClock.service}
        showEditButtons={!Boolean(timeClock?.service_is_billed)}
        handleRefresh={refreshTimeClock}
        deleteRefresh={closeDialog}
      />
    );
  }

    const { icon: DivisionTypeIcon, color: DivisionTypeColor } = getDivisionTypeIndicator(
      timeClock.division_type
    );

  const _timeClock = (
    <div className="time-clock main">
      {timeClock.read_only &&
        <div className="read-only-heading">Read Only</div>
      }
      {!timeCardOnly &&
        <TimeClockCustomer
          timeClock={timeClock}
        />
      }
      <Formik
        initialValues={setInitialValues(timeClock)}
        onSubmit={onSubmit}
        validate={(values, buttonName, props) => {
          if(values.warnings){
            setMessage({
              id: 'warning',
              msg: values.warnings.join("<br />"),
            })
          }
          else{
            setMessage(null);
          }
          setSubMessage(null);
          let errors = {};

          errors = timeClockValidateUser(errors, values, setMessage, isAuthenticated);

          errors = timeClockValidateLaborType(errors, timeClock, values, setSubMessage);

          errors = timeClockValidateServiceActivity(errors, timeClock, values, setSubMessage);

          errors = timeClockValidateTimer(errors, timeClock, values, setSubMessage);

          errors = timeClockValidateSignature(errors, values, setSubMessage);

          return errors;
        }}
      >
        {({ values, errors, isSubmitting, submitForm, setFieldValue, setSubmitting, validateForm }) => {
          const handleSubmit = (buttonName) => {
            setFieldValue('submitting_button', buttonName);
            setSubmitting(true);
            const geoError = {
              id: 'negative',
              msg: <>Your location information is required to submit a time card.<br />
                  Please check you device's location permissions and try again,<br />
                  or contact your administrator.</>,
            };

            const _onGetCurrentLocation  = () => {
              const options = {
                enableHighAccuracy: false,
                timeout: 15000,
                maximumAge: 0
              };
              navigator.geolocation.getCurrentPosition((position) => {
              //  Success
                setFieldValue('location', {
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
                });
                setTimeout(submitForm, 1000);
              }, (err) => {
              //  Error
                setFieldValue('location', {
                  error: err && err?.message ? err.message : "Geolocation unavailable",
                });
                if(timeClock.require_geo){
                  setMessage(geoError)
                  setSubmitting(false);
                }
                else{
                  setTimeout(submitForm, 1000);
                }
              }, options);
            }

          //  Get location peremissions/info
            if ( navigator.permissions && navigator.permissions.query) {
              //try permissions APIs first
              navigator.permissions.query({ name: 'geolocation' }).then(function(result) {
                  // Will return ['granted', 'prompt', 'denied']
                  const permission = result.state;
                  if ( permission === 'granted' || permission === 'prompt' ) {
                      _onGetCurrentLocation();
                  }
              });
            } else if (navigator.geolocation) {
              //then Navigation APIs
              _onGetCurrentLocation();
            }
            else {
            //  Error
              if(timeClock.require_geo){
                setMessage(geoError)
                setSubmitting(false);
              }
              else{
                setFieldValue('location', {
                  error: "Geolocation not supported",
                });
                setTimeout(submitForm, 1000);
              }
            }
          };
        const loading = (isSubmitting || loadingSheet);

        return (
          <>
          {loading &&
            <div className="submitting"><DefaultLoader propClass="loader" /></div>
          }
          {message && <Alert kind={message.id}>{message.msg}</Alert>}
          {subMessage && <Alert kind={subMessage.id}>{subMessage.msg}</Alert>}
          {(!serviceHash && !newTimeCard) &&
            <TimeClockChange
              timeClock={timeClock}
              isSubmitting={loading}
              getNewTimeClock={getNewTimeClock}
              changeTimeCard={changeTimeCard}
              setFieldValue={setFieldValue}
              values={values}
              submitForm={handleSubmit}
            />
          }
          <Card
            variant="outlined"
            className={timeClock.service_status === 'complete' ? 'complete' : ''}
          >
            <TimeClockUser
              timeClock={timeClock}
              isSubmitting={loading}
              values={values}
              submitForm={handleSubmit}
              // getNewTimeClock={getNewTimeClock}
              newTimeCard={!serviceHash && newTimeCard}
              timeCardOnly={timeCardOnly}
              setFieldValue={setFieldValue}
              message={subMessage}
              errors={errors}
            />

            {showDivisionInfo &&
              <div className="div-info">
                <Typography variant="h6" className="title">
                  {Boolean(timeClock.division_type) &&
                    <>
                    <CollapsibleIcon
                      collapse={false}
                      color={DivisionTypeColor}
                      tooltip={timeClock.division_type}
                      className="division-type-icon"
                    >
                      {<DivisionTypeIcon />}
                    </CollapsibleIcon>
                    </>
                  }
                  <span>
                    {timeClock.division_title}
                    {Boolean(timeClock.division_type) && <><br />{timeClock.division_type}</>}
                  </span>
                </Typography>
                <TimeClockCustomer
                  timeClock={timeClock}
                />
              </div>
            }

            {showAdminHeading &&
              (!timeClock?.service_is_time_card ? (
                <div className="manual-entry">
                  <div>
                    {adminButtons}
                    <Typography variant="h6" className="text-orange">Manual Labor Entry</Typography>
                  </div>
                  <div className="manual-info">
                    <FaUserCheck className="edit-icon medium" />
                    <div className="info">
                      <span>Created by:</span>
                      <span>{timeClock.service_author}</span>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="manual-entry buttons-only">
                  {adminButtons}
                </div>
              ))
            }

            <TimeClockSignature
              timeClock={timeClock}
              values={values}
              isSubmitting={loading}
              submitForm={handleSubmit}
            >
              <TimeClockLaborTypeInline
                timeClock={timeClock}
                isSubmitting={loading}
                message={subMessage}
                errors={errors}
                inTimeClock
              />
              <TimeClockServiceActivityInline
                timeClock={timeClock}
                isSubmitting={loading}
                values={values}
                message={subMessage}
                errors={errors}
                inTimeClock
              />
              <TimeClockTimer
                timeClock={timeClock}
                submitForm={handleSubmit}
                isSubmitting={loading}
                values={values}
                setFieldValue={setFieldValue}
                message={subMessage}
                errors={errors}
                validateForm={validateForm}
                setMessage={setMessage}
              />
            </TimeClockSignature>

            <TimeClockLaborType
              timeClock={timeClock}
              submitForm={handleSubmit}
              isSubmitting={loading}
              values={values}
              message={subMessage}
              errors={errors}
            />
            <TimeClockServiceActivity
              timeClock={timeClock}
              submitForm={handleSubmit}
              isSubmitting={loading}
              values={values}
              message={subMessage}
              errors={errors}
            />
            <TimeClockTravel
              timeClock={timeClock}
              submitForm={handleSubmit}
              isSubmitting={loading}
              values={values}
              message={subMessage}
              errors={errors}
            />
            <TimeClockLog
              timeClock={timeClock}
            />
          </Card>
          </>
        )}}
      </Formik>
    </div>
  );

  return (
    loading ? (
      <DefaultLoader style={{padding: "2rem"}} />
    ) : (
      !error ? (
        !timeCardOnly ? (
          <Layout
            name={(<div className="ml-2">
              <Typography variant="h5">Timeclock</Typography>
              <Typography className="normal-case">
                {timeClock.division_title}
                {timeClock.division_type && ` (${timeClock.division_type})`}
              </Typography>
            </div>)}
            icon={<BsClockHistory />}
            pageHeaderProps={{slim: true}}
          >
            {_timeClock}
          </Layout>
        ) : (
          _timeClock
        )
      ) : (
        <Empty message={error} style={{"color": "var(--color-primary)"}}>
          <PunchClockIcon />
        </Empty>
      )
    )
  );
};

TimeClock.propTypes = {};

export default TimeClock;
