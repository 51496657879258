import React, {useState, useRef} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Tooltip,
  IconButton,
} from "@mui/material";
import { ListItem, ListItemIcon, ListItemText, } from "@mui/material";
import Indicators from "common/Indicators";
import { TimeClockDialog } from "components/TimeClock";
import { ClockIcon } from "common/ClockIcon";
import PunchClockIcon from '@mui/icons-material/PunchClock';
import { getTimeClockSelector, refreshUserTimeCards, } from "components/TimeClock/timeClockSlice";
import { IconButtonDropdown } from "common/ButtonDropdown";
import { ReactComponent as IconJob } from "assets/images/icons/icon-job.svg";
import styled from "styled-components";
import { fetchCurrentUser, updateAuthUserProcessed } from "features/Auth/authSlice";

const PunchClockMenuIcon = styled(PunchClockIcon)`
  width: 1.25em;
  height: 1.25em;
`;

const TimeClockIndicator = () => {
  const _processed = useSelector((state) => state.auth.user.data._processed);

  if(_processed && _processed?.time_cards_open){
    return (<TimeClockIndicatorOpenCard openCard={_processed.time_cards_open} />);
  }
  else if(_processed && _processed?.time_clock_divisions){
    return (<TimeClockIndicatorStartCard divisions={_processed.time_clock_divisions} />);
  }
  return (<></>);
};

const TimeClockIndicatorOpenCard = ({openCard}) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.user.data);
  const timeClock = useSelector(getTimeClockSelector);

  const handleCloseDialog = async () => {
    if(timeClock
      && timeClock?.service_status === 'complete'
      && currentUser?._processed?.time_cards_open
    ){
      await dispatch(fetchCurrentUser());
    }
    dispatch(refreshUserTimeCards());
  };

  return (
    <TimeClockDialog
      divisionHash={openCard.division_hash}
      serviceHash={openCard.service_hash}
      onClose={handleCloseDialog}
      showDivisionInfo
    >
      <Tooltip title="View Time Card">
        <IconButton
          size="large"
          // className="non-mui-icon"
          style={{ color: "white" }}
        >
          <Indicators
            orange={true}
            // marginRightStart={0.125}
          >
            <ClockIcon run={true} />
          </Indicators>
        </IconButton>
      </Tooltip>
    </TimeClockDialog>
  );
};

const TimeClockIndicatorStartCard = ({divisions}) => {
  const dispatch = useDispatch();
  const dropdownRef = useRef();
  const currentUser = useSelector((state) => state.auth.user.data);
  const [divisionHash, setDivisionHash] = useState(null);
  const timeClock = useSelector(getTimeClockSelector);

  const handleCloseDropdown = () => {
    if (dropdownRef.current) {
      dropdownRef.current.close();
    }
  };

  const handleCloseDialog = () => {
    setDivisionHash(null);

    if(timeClock
      && timeClock.clock_status === 'clocked_in'
      && !currentUser?._processed?.time_cards_open
    ){
      dispatch(updateAuthUserProcessed({
        'time_cards_open': {
          'division_hash': timeClock.division_hash,
          'service_hash': timeClock.service_hash,
        },
      }));
    }
    dispatch(refreshUserTimeCards());
  };

  return (
    <>
    <IconButtonDropdown
      ref={dropdownRef}
      icon={PunchClockMenuIcon}
      tooltip="Actions"
      color="inherit"
      className="text-white"
      // showArrow
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <div>
        {divisions.map((division, i) => {
          return (
            <ListItem
              button
              onClick={() => {
                setDivisionHash(division.division_hash);
                handleCloseDropdown();
              }}
              key={i}
            >
              <ListItemIcon>
                <IconJob />
              </ListItemIcon>
              <ListItemText primary={<>{division.title}</>} />
            </ListItem>
          );
        })}
      </div>
    </IconButtonDropdown>
    <TimeClockDialog
      divisionHash={divisionHash}
      manualOpen={Boolean(divisionHash)}
      defaultUser={currentUser.uid}
      onClose={handleCloseDialog}
      empty
      showDivisionInfo
    />
    </>
  );
};

TimeClockIndicator.propTypes = {};

export default TimeClockIndicator;
