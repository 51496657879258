import React, { useRef } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import SimpleBar from "simplebar-react";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import {
  // RiShieldKeyholeLine,
  // RiBookOpenLine,
  RiRoadMapLine,
  RiClipboardFill,
} from "react-icons/ri";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { FaBoxes, FaTasks } from "react-icons/fa";
import { HiDocumentReport } from "react-icons/hi";
import { BiBuildingHouse } from "react-icons/bi";
import { CgMenuRight } from "react-icons/cg";
import { ReactComponent as IconHub } from "assets/images/icons/icon-hub.svg";
import { ReactComponent as IconJob } from "assets/images/icons/icon-job.svg";
import { ReactComponent as IconProvider } from "assets/images/icons/icon-provider.svg";
import { ReactComponent as IconCustomer } from "assets/images/icons/icon-customer.svg";

import Drawer from "common/Drawer";
import { withPermissions } from "providers";
import { hasPermission } from "utility";
import Support from "features/Support";

const phxClientNav = [
  { url: "/hub", icon: <IconHub />, text: "Hub" },
  { url: "/jobs", icon: <IconJob />, text: "Jobs" },
  { url: "/members", icon: <IconProvider />, text: "Providers" },
  { url: "/customers", icon: <IconCustomer />, text: "Customers" },
  { url: "/tasks", icon: <FaTasks />, text: "Schedule" },
  { url: "/compliance-manager", icon: <RiClipboardFill />, text: "Compliance" },
  { url: "/reports", icon: <HiDocumentReport />, text: "Reports" },
  { url: "/map", icon: <RiRoadMapLine />, text: "Map" },
  { url: "/inventory", icon: <FaBoxes />, text: "Inventory" },
  { url: "/users", icon: <PeopleAltIcon />, text: "Users" },
  // {
  //   url: "https://helixco.zendesk.com",
  //   icon: <RiBookOpenLine />,
  //   text: "Guides",
  //   external: true,
  // },
  // {
  //   url: "https://admin.helixco.io/admin/phoenix/links",
  //   icon: <RiShieldKeyholeLine />,
  //   text: "Admin",
  //   external: true,
  // },
];

const memberAdminNav = [
  { url: "/hub", icon: <IconHub />, text: "Hub" },
  { url: "/jobs", icon: <IconJob />, text: "Jobs" },
  { url: "/members", icon: <IconProvider />, text: "Providers" },
  { url: "/customers", icon: <IconCustomer />, text: "Customers" },
  { url: "/tasks", icon: <FaTasks />, text: "Schedule" },
  { url: "/map", icon: <RiRoadMapLine />, text: "Map" },
  { url: "/inventory", icon: <FaBoxes />, text: "Inventory" },
  { url: "/users", icon: <PeopleAltIcon />, text: "Users" },
  // {
  //   url: "https://helixco.zendesk.com",
  //   icon: <RiBookOpenLine />,
  //   text: "Guides",
  //   external: true,
  // },
];

const memberFieldNav = [
  { url: "/hub", icon: <IconHub />, text: "Hub" },
  { url: "/jobs", icon: <IconJob />, text: "Jobs" },
  { url: "/members", icon: <IconProvider />, text: "Providers" },
  { url: "/tasks", icon: <FaTasks />, text: "Schedule" },
  { url: "/map", icon: <RiRoadMapLine />, text: "Map" },
  // {
  //   url: "https://helixco.zendesk.com",
  //   icon: <RiBookOpenLine />,
  //   text: "Guides",
  //   external: true,
  // },
];

const memberAffiliateNav = [
  { url: "/hub", icon: <IconHub />, text: "Hub" },
  { url: "/jobs", icon: <IconJob />, text: "Jobs" },
  { url: "/tasks", icon: <FaTasks />, text: "Schedule" },
  { url: "/map", icon: <RiRoadMapLine />, text: "Map" },
  // {
  //   url: "https://helixco.zendesk.com",
  //   icon: <RiBookOpenLine />,
  //   text: "Guides",
  //   external: true,
  // },
];

const defaultNav = [
  // { url: "/hub", icon: <IconHub />, text: "Hub" },
  { url: "/jobs", icon: <IconJob />, text: "Jobs" },
  { url: "/tasks", icon: <FaTasks />, text: "Schedule" },
  { url: "/map", icon: <RiRoadMapLine />, text: "Map" },
  // {
  //   url: "https://helixco.zendesk.com",
  //   icon: <RiBookOpenLine />,
  //   text: "Guides",
  //   external: true,
  // },
];

const getNav = (roles, processed) => {
  if (
    hasPermission(roles, [
      "admin",
      "phx_sub_admin",
      "phx_client_admin",
      "operations",
      "accounting",
      "compliance_admin",
    ])
  ) {
    return phxClientNav;
  } else if (processed.is_affiliate_member && !processed.is_d2d) {
    return memberAffiliateNav;
  } else if (hasPermission(roles, ["member_admin_agent", "member_estimator"])) {
    return memberAdminNav;
  } else if (hasPermission(roles, ["member_field_agent"])) {
    return memberFieldNav;
  } else {
    return defaultNav;
  }
};

const Sidebar = ({ roles }) => {
  const drawerRef = useRef();
  const processed = useSelector((state) => state.auth.user.data._processed);

  const handleClose = () => {
    if (drawerRef.current) {
      drawerRef.current.close();
    }
  };

  return (
    <Drawer
      ref={drawerRef}
      anchor="right"
      primary="Navigation"
      secondary="Site Navigation"
      icon={<CgMenuRight />}
      action={
        <IconButton edge="end" style={{ marginLeft: "1rem" }} size="large">
          <CgMenuRight style={{ color: "white" }} />
        </IconButton>
      }
    >
      <SimpleBar>
        <nav className="sidebar__nav-wrapper">
          <ul className="sidebar__nav">
            {getNav(roles, processed).map((navItem) => {
              return navItem.external ? (
                <li className="sidebar__nav-item" key={navItem.text}>
                  <a
                    className="sidebar__nav-item-link"
                    href={navItem.url}
                    target="blank"
                    onClick={handleClose}
                  >
                    {/* <img
                        src={navItem.icon}
                        alt="Dashboard Icon"
                        className="sidebar__nav-icon nav-icon"
                      /> */}
                    <div className="sidebar__nav-icon nav-icon">{navItem.icon}</div>
                    <span className="sidebar__nav-title">{navItem.text}</span>
                  </a>
                </li>
              ) : (
                <li className="sidebar__nav-item" key={navItem.text}>
                  <NavLink
                    activeClassName="is-active"
                    className="sidebar__nav-item-link"
                    to={navItem.url}
                    onClick={handleClose}
                  >
                    {/* <img
                        src={navItem.icon}
                        alt="Dashboard Icon"
                        className="sidebar__nav-icon nav-icon"
                      /> */}
                    <div className="sidebar__nav-icon nav-icon">{navItem.icon}</div>
                    <span className="sidebar__nav-title">{navItem.text}</span>
                  </NavLink>
                </li>
              );
            })}
            {Boolean(processed.company_alias) && (
              <li className="sidebar__nav-item">
                <NavLink
                  activeClassName="is-active"
                  className="sidebar__nav-item-link"
                  to={processed.company_alias}
                  onClick={handleClose}
                >
                  {/* <img
                      src={company}
                      alt="Dashboard Icon"
                      className="sidebar__nav-icon nav-icon"
                    /> */}
                  <div className="sidebar__nav-icon nav-icon">
                    <BiBuildingHouse />
                  </div>
                  <span className="sidebar__nav-title">My Company</span>
                </NavLink>
              </li>
            )}
            <Support>
              <li className="sidebar__nav-item">
                <div
                  className="sidebar__nav-item-link"
                  onClick={() => {
                    window.zE("webWidget", "open");
                  }}
                >
                  <div className="sidebar__nav-icon nav-icon">
                    <ContactSupportIcon />
                  </div>
                  <span className="sidebar__nav-title">Support</span>
                </div>
              </li>
            </Support>
          </ul>
        </nav>
      </SimpleBar>
    </Drawer>
  );
};

export default withPermissions(Sidebar);
