
import React from "react";
import {
  Typography,
  IconButton,
} from "@mui/material";
import { BsCalendar4Event,  } from "react-icons/bs";
import { HoursInfo, TimeInfo } from "./TimeClockTimer";
import { ClockIcon } from "common/ClockIcon";
import { BsPerson, BsTools } from "react-icons/bs";
import Avatar from "common/Avatar";
import moment from "moment-timezone";
import TimeClockAdminButtons from "./TimeClockAdminButtons";
import TimeClockDialog from "../TimeClockDialog";
import { ReactComponent as TimeCardIcon } from "assets/images/icons/icon-time-card.svg";
import { OpenInNew } from "@mui/icons-material";
import { ImFilesEmpty } from "react-icons/im";
import { checkDiffDate, parseHours } from "../utils";


const TimeCardLineItem = ({
  card,
  division=null,
  summary,
  handleRefresh,
  showAdminButtons,
  isBulkEditItem,
  onClick,
  clickOverride,
}) => {
  const dateDiff = checkDiffDate(card);

  return (
    <div className={`time-card-line-item ${clickOverride ? 'click-override' : ''}`} onClick={onClick} >
      <div className={`card ${!card.is_time_card ? 'manual' : ''}`}>
        {isBulkEditItem &&
          <div className="avatar">
            <Avatar
              title={`${card?.ds_user_full_name}`}
              link={card._files?.user_profile_pic_medium ? card._files?.user_profile_pic_medium : null}
              size="normal"
            />
          </div>
        }
        <div className="wrapper">
          {isBulkEditItem &&
            <div className="user-info">
              <Typography variant="h6">{card.ds_user_full_name}</Typography>
              <Typography variant="subtitle2">{card.ds_user_uid ? `Account User` : `Non-Account User`}</Typography>
              <div
                onClick={(e) => e.stopPropagation()}
              >
                <TimeClockDialog
                  divisionHash={division.field_public_hash}
                  serviceHash={card.hash}
                  onClose={() => {
                    if(card.status !== 'complete'){
                      handleRefresh();
                    }
                  }}
                >
                  <IconButton
                    variant="contained"
                    size="small"
                    className="detail bulk-edit-icon"
                  >
                    <TimeCardIcon className="time-card" />
                    <OpenInNew className="open" />
                  </IconButton>
                </TimeClockDialog>
              </div>
            </div>
          }
          <div className="heading">
            <Typography  variant="subtitle1">
              <BsCalendar4Event className="icon" />
              {moment(card.date).format('MM/DD/YY')}
              {Boolean(dateDiff) &&
                ` - ${dateDiff.format('MM/DD/YY')}`
              }
            </Typography>
              <div className={`status ${!showAdminButtons ? 'right' : ''}`}>
            {card.is_time_card ? (
              <>
                <ClockIcon
                  className="icon"
                  run={card.clock_status === 'clocked_in'}
                />
                {card.clock_status === 'on_break' ? (
                  <span className="text-yellow">On Break</span>
                ) : (
                  <>
                  {card.status === 'in_progress' &&
                    <span className="text-orange">In Progress</span>
                  }
                  {card.status === 'awaiting_signature' &&
                    <span className="text-red">Awaiting Signature</span>
                  }
                  {card.status === 'complete' &&
                    <span className="text-green-alt">Completed</span>
                  }
                  </>
                )}
                </>
            ) : (
              <>
              <span className="icon disabled">
                <ClockIcon />
              </span>
              <span className="text-orange">Manual Labor Entry</span>
              </>
            )}
              </div>
            {showAdminButtons &&
              <TimeClockAdminButtons
                nid={card.daily_sheet_nid}
                showEditButtons={!Boolean(card.billed)}
                hash={card.hash}
                handleRefresh={handleRefresh}
                isSmall
              />
            }
          </div>

          {!summary &&
            <div className="labor-info">
              <span>
                <BsPerson className="icon small" /> {card.service_type}
              </span>
              {card.service_activity &&
                <span>
                  <BsTools className="icon small" /> {card.service_activity}
                </span>
              }
            </div>
          }
          {(!summary || card.status !== 'complete') &&
            <TimeInfo timeClock={card} simple />
          }

          {division !== null ? (
            <HoursInfo timeClock={card} />
          ) : (
            <div className={`hours-division ${summary ? 'summary' : 'detail'}`}>
              <div className={`hours`} >
                <TimeCardIcon className="icon" />
                {summary ? (
                  parseHours(card.service_hours_total)
                ) : (
                  <HoursInfo timeClock={card} />
                )}
              </div>
              <div className="division">
                <ImFilesEmpty className="icon"/>
                <div>
                  <div>{card.division_title}</div>
                  <div>{card.division_customer} - {card.division_customer_location}</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

TimeCardLineItem.propTypes = {};

export default TimeCardLineItem;
