import React, { useRef } from "react";
import {
  Grid,
  ListItemIcon,
  MenuItem,
  Typography,
  InputAdornment,
  LinearProgress,
} from "@mui/material";
import { MoreVert, Delete, FileCopy } from "@mui/icons-material";

import Alert from "common/Alert";
import { CheckboxFormField, SelectFormField, AutocompleteFormField } from "common/Fields";
import { TextFormField } from "common/Fields";
import { BatchRow } from "./Styled";
import { IconButtonDropdown } from "common/ButtonDropdown";
import { toArray, first } from "lodash";
import { checkTMTermLaborItem, checkTMMarkup } from "components/TMTerms/utils";
import TMTermsDailySheetMarkupComponent from "components/TMTerms/forms/components/TMTermsDailySheetMarkupComponent";
import Guard from "common/Guard";

const getStatusOfSubmissions = (submissions, i) => {
  if (!submissions[i]?.response) return null;
  return submissions[i]?.response?.status === "fulfilled"
    ? "positive"
    : "negative";
};

const getMessageOfSubmission = (submissions, i) => {
  if (!submissions[i]?.response) return null;
  if (submissions[i]?.response?.status === "fulfilled") {
    return "Created successfully";
  } else {
    const errorMessage = submissions[i]?.response?.message;
    return errorMessage;
  }
};

const hasDifferentLaborTypes = (entries, labor, uid) => {
  let different = false;
  entries.forEach((entry) => {
    if (uid && entry.field_ds_user?.entity_id === uid && labor) {
      if (
        entry.field_ds_labor_type_text &&
        entry.field_ds_labor_type_text !== labor
      ) {
        different = true;
      }
    }
  });

  return different;
};

const BatchItem = ({
  values,
  arrayHelpers,
  isSubmitting,
  loading,
  error,
  users,
  memberNid,
  loadAvailableUsers,
  i,
  setFieldValue,
  laborTypes,
  rates,
  areas,
  loadingAreas,
  errorAreas,
  loadWorkareas,
  calcOverallHours,
  entry,
  nid,
  submissions,
  getUsers,
  getAreas,
  tmTerms,
  visibility
}) => {
  const dropdownRef = useRef();
  const status = getStatusOfSubmissions(submissions, i);

  const onUserChange = async (i, value, setFieldValue, values) => {
    setFieldValue(`entries.${i}.field_ds_user`, value);

    if(values.entries[i].field_ds_labor_type_text === ''){
      if(value?.service_types){
        laborTypeUpdate(first(value?.service_types));
      }
      else if(value?.user?.service_types){
        laborTypeUpdate(first(value?.user?.service_types));
      }
    }
  };

  const laborTypeUpdate = (value) => {
    setFieldValue(
      `entries.${i}.field_ds_labor_type_text`,
      value
    );
    if (rates) {
      for (let key in rates) {
        if (rates[key].name === value) {
          const serviceRate = rates[key].s;
          setFieldValue(`entries.${i}.service-rate`, serviceRate);
          let overtimeRate = rates[key].o;
          if(checkTMTermLaborItem(tmTerms?.overtime, key)){
            overtimeRate = serviceRate * Number(tmTerms.overtime.multiplier);
          }
          setFieldValue(`entries.${i}.overtime-rate`, overtimeRate);
          let afterHoursRate = rates[key].e;
          if(checkTMTermLaborItem(tmTerms?.after_hours, key)){
            afterHoursRate = serviceRate * Number(tmTerms.after_hours.multiplier);
          }
          setFieldValue(`entries.${i}.after-rate`, afterHoursRate);
          setFieldValue(`entries.${i}.travel-rate`, rates[key].t);

          let travel = 0;
          if(checkTMTermLaborItem(tmTerms?.travel, key)){
            travel = tmTerms.travel.value;
          }
          setFieldValue(`entries.${i}.field_ds_travel_billable_hrs`, travel);
          setFieldValue(`entries.${i}.field_ds_total_hours`, travel);

          let perDiem = 0;
          if(checkTMTermLaborItem(tmTerms?.per_diem, key)){
            perDiem = tmTerms.per_diem.value;
          }
          setFieldValue(`entries.${i}.field_ds_perdiem_total`, perDiem);

          let ppe = 0;
          if(checkTMTermLaborItem(tmTerms?.ppe, key)){
            ppe = tmTerms.ppe.value;
          }
          setFieldValue(`entries.${i}.field_ds_service_ppe_total`, ppe);

          let stc = 0;
          if(checkTMTermLaborItem(tmTerms?.small_tools_charge, key)){
            stc = Number(tmTerms.small_tools_charge.percent) * 100;
          }
          setFieldValue(`entries.${i}.field_ds_service_stc_prct`, stc);

          let lodging = 0;
          if(checkTMTermLaborItem(tmTerms?.lodging, key)){
            lodging = tmTerms.lodging.value;
          }
          setFieldValue(`entries.${i}.field_ds_service_lodging_total`, lodging);

          let markup = '';
          let markupPercent = '';
          let markupType = '';
          const _markup = checkTMMarkup(tmTerms, null, key);
          if(_markup){
            markup = _markup?.value ? _markup.value : '';
            markupPercent = _markup?.percent ? _markup.percent : '';
            markupType = _markup?.value ? 'flat' : 'percent';
          }
          setFieldValue(`entries.${i}.field_ds_markup`, markup);
          setFieldValue(`entries.${i}.field_ds_percent_markup`, markupPercent);
          setFieldValue(`entries.${i}.markup_type`, markupType);
        }
      }
    }
  }

  const message = getMessageOfSubmission(submissions, i);

  return (
    <BatchRow status={status}>
      {submissions[i]?.loading && (
        <div style={{ position: "absolute", left: 0, right: 0, top: 0 }}>
          <LinearProgress color="secondary" />
        </div>
      )}
      <Grid container spacing={0}>
        <Grid item xxs={12}>
          {values.entries[i].field_ds_work_area_allocation?.name && (
            <Typography gutterBottom>
              Work Area: {values.entries[i].field_ds_work_area_allocation?.name}
            </Typography>
          )}

          {status && message && (
            <div style={{ marginBottom: "1rem" }}>
              <Alert margin="none" disableElevation kind={status}>
                {message}
              </Alert>
            </div>
          )}
        </Grid>
        <Grid item xxs={12} sm={6} md={3}>
          <AutocompleteFormField
            name={`entries.${i}.field_ds_user`}
            label="User"
            disabled={isSubmitting}
            options={toArray(users).map((user) => ({
              ...user,
              entity_id: user.uid,
              uid: user.uid,
              name: user.name,
              service_types: user.service_types_json,
            }))}
            loading={loading}
            fetchData={getUsers}
            variant="outlined"
            size="small"
            margin="normal"
            onChange={(e, value) => {
              onUserChange(i, value, setFieldValue, values);
            }}

            groupBy={(option) => option.type === 'user' ? 'Account' : 'Offline'}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.entity_id}>
                  {option.name}
                </li>
              );
            }}
            isOptionEqualToValue={(option, value) => Number(option.entity_id) === Number(value.entity_id)}
          />
        </Grid>
        <Grid item xxs={12} sm={6} md={3}>
          <SelectFormField
            fullWidth
            name={`entries.${i}.field_ds_labor_type_text`}
            variant="outlined"
            label="Labor Type"
            labelwidth={80}
            required
            margin="normal"
            disabled={isSubmitting}
            options={laborTypes.map((type) => ({
              value: type.name,
              label: type.name,
            }))}
            onChange={(e) => {
              laborTypeUpdate(e.target.value);
            }}
          />
        </Grid>
        <Grid item xxs={6} sm={3} md={1}>
          <TextFormField
            fullWidth
            name={`entries.${i}.field_ds_start_time`}
            variant="outlined"
            label="Start"
            disabled={isSubmitting}
            labelwidth={30}
            size="small"
            margin="normal"
            helperText="ex 8:00 AM"
            onChange={(e) => {
              setFieldValue(`entries.${i}.field_ds_start_time`, e.target.value);
              setFieldValue(
                `entries.${i}.field_ds_total_hours`,
                calcOverallHours({
                  ...entry,
                  field_ds_start_time: e.target.value,
                })
              );
            }}
          />
        </Grid>
        <Grid item xxs={6} sm={3} md={1}>
          <TextFormField
            fullWidth
            name={`entries.${i}.field_ds_breaks_start_time`}
            label="Out"
            variant="outlined"
            disabled={isSubmitting}
            size="small"
            labelwidth={25}
            helperText="ex 12:00 PM"
            margin="normal"
            onChange={(e) => {
              setFieldValue(
                `entries.${i}.field_ds_breaks_start_time`,
                e.target.value
              );
              setFieldValue(
                `entries.${i}.field_ds_total_hours`,
                calcOverallHours({
                  ...entry,
                  field_ds_breaks_start_time: e.target.value,
                })
              );
            }}
          />
        </Grid>
        <Grid item xxs={6} sm={3} md={1}>
          <TextFormField
            fullWidth
            name={`entries.${i}.field_ds_breaks_end_time`}
            variant="outlined"
            label="In"
            disabled={isSubmitting}
            labelwidth={15}
            helperText="ex 1:00 PM"
            size="small"
            margin="normal"
            onChange={(e) => {
              setFieldValue(
                `entries.${i}.field_ds_breaks_end_time`,
                e.target.value
              );
              setFieldValue(
                `entries.${i}.field_ds_total_hours`,
                calcOverallHours({
                  ...entry,
                  field_ds_breaks_end_time: e.target.value,
                })
              );
            }}
          />
        </Grid>
        <Grid item xxs={6} sm={3} md={1}>
          <TextFormField
            fullWidth
            name={`entries.${i}.field_ds_end_time`}
            label="End"
            variant="outlined"
            disabled={isSubmitting}
            size="small"
            helperText="ex 5:00 PM"
            labelwidth={25}
            margin="normal"
            onChange={(e) => {
              setFieldValue(`entries.${i}.field_ds_end_time`, e.target.value);
              setFieldValue(
                `entries.${i}.field_ds_total_hours`,
                calcOverallHours({
                  ...entry,
                  field_ds_end_time: e.target.value,
                })
              );
            }}
          />
        </Grid>
        <Grid item xxs={6} md={1}>
          <TextFormField
            fullWidth
            name={`entries.${i}.field_ds_travel_billable_hrs`}
            variant="outlined"
            label="Travel"
            disabled={isSubmitting}
            labelwidth={40}
            size="small"
            margin="normal"
            onChange={(e) => {
              setFieldValue(
                `entries.${i}.field_ds_travel_billable_hrs`,
                e.target.value
              );
              setFieldValue(
                `entries.${i}.field_ds_total_hours`,
                calcOverallHours({
                  ...entry,
                  field_ds_travel_billable_hrs: e.target.value,
                })
              );
            }}
          />
        </Grid>
        <Grid item xxs={6} md={1}>
          <TextFormField
            fullWidth
            name={`entries.${i}.field_ds_total_hours`}
            label="Total"
            variant="outlined"
            disabled
            labelwidth={35}
            margin="normal"
            size="small"
            endAdornment={<InputAdornment position="end">hrs</InputAdornment>}
          />
        </Grid>
        <Grid item xxs={12}>
          {hasDifferentLaborTypes(
            values.entries,
            values.entries[i].field_ds_labor_type_text,
            values.entries[i].field_ds_user?.entity_id
          ) && (
            <Typography color="error">
              Labor types are not consistent for this user.
            </Typography>
          )}
        </Grid>
      </Grid>
      <div>
        <IconButtonDropdown
          icon={MoreVert}
          ref={dropdownRef}
          size="small"
          tooltip="Actions"
          disabled={isSubmitting}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div style={{ minWidth: "250px" }}>
            <div style={{ padding: "1rem" }}>
              <AutocompleteFormField
                name={`entries.${i}.field_ds_work_area_allocation`}
                label="Allocate to Work Area"
                disabled={isSubmitting}
                options={toArray(areas).map((user) => ({
                  name: user.title,
                  nid: user.nid,
                }))}
                loading={loadingAreas}
                fetchData={getAreas}
                variant="outlined"
                size="small"
                margin="normal"
              />
              <TextFormField
                fullWidth
                name={`entries.${i}.field_ds_perdiem_total`}
                label="Perdiem"
                variant="outlined"
                margin="normal"
                disabled={isSubmitting}
                size="small"
                labelwidth={60}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
              />
              <TextFormField
                fullWidth
                name={`entries.${i}.field_ds_service_ppe_total`}
                label="PPE"
                variant="outlined"
                margin="normal"
                disabled={isSubmitting}
                size="small"
                labelwidth={60}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
              />
              <TextFormField
                fullWidth
                name={`entries.${i}.field_ds_service_stc_prct`}
                label="Small Tools Charge"
                variant="outlined"
                margin="normal"
                disabled={isSubmitting}
                size="small"
                labelwidth={60}
                endAdornment={
                  <InputAdornment position="end">%</InputAdornment>
                }
              />
              <TextFormField
                fullWidth
                name={`entries.${i}.field_ds_service_lodging_total`}
                label="Lodging"
                variant="outlined"
                margin="normal"
                disabled={isSubmitting}
                size="small"
                labelwidth={60}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
              />
              <TMTermsDailySheetMarkupComponent
                namePrefix={`entries.${i}.`}
                isSubmitting={isSubmitting}
                values={values.entries[i]}
                dsType="labor"
              />
              <Guard customOverrideName="canManageEntryVisibility">
                <CheckboxFormField name={`entries.${i}.field_visibility`} label="Do not share with provider" />
              </Guard>
            </div>
            <MenuItem
              onClick={() => {
                if (dropdownRef.current) {
                  dropdownRef.current.close();
                }
                arrayHelpers.push(values.entries[i]);
              }}
            >
              <ListItemIcon>
                <FileCopy fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">Duplicate</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                if (dropdownRef.current) {
                  dropdownRef.current.close();
                }
                arrayHelpers.remove(i);
              }}
            >
              <ListItemIcon>
                <Delete fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">Delete</Typography>
            </MenuItem>
          </div>
        </IconButtonDropdown>
      </div>
    </BatchRow>
  );
};

BatchItem.propTypes = {};

export default BatchItem;
