import React, { useState, useEffect } from "react";
import { TablePagination } from "@mui/material";

const Pagination = ({
  pagination,
  onPageChange,
  handleChangeRowsPerPage,
  action,
}) => {
  const [count, setCount] = useState(pagination?.count ? pagination?.count : 0);
  const [currentPage, setCurrentPage] = useState(pagination?.current_page ? pagination?.current_page : 0);
  const [perPageTotal, setPerPageTotal] = useState(pagination?.per_page_total ? pagination?.per_page_total : 25);

  useEffect(() => {
    setCount(pagination?.count);
    setCurrentPage(pagination?.current_page);
    setPerPageTotal(pagination?.per_page_total);
  }, [pagination?.count]); // eslint-disable-line

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        // background: "white",
        justifyContent: "space-between",
      }}
    >
      <div>{action}</div>
      <TablePagination
        rowsPerPageOptions={(handleChangeRowsPerPage ? [25, 50, 100, 200] : [])}
        labelRowsPerPage="Rows:"
        component="div"
        count={Number(count)}
        rowsPerPage={perPageTotal ? Number(perPageTotal) : 25}
        page={currentPage}
        SelectProps={{
          inputProps: { "aria-label": "rows per page" },
          native: true,
        }}
        onPageChange={(e, newPage) => {
          setCurrentPage(newPage)
          onPageChange(e, newPage);
        }}
        onRowsPerPageChange={(e) => {
          setPerPageTotal(e.target.value)
          handleChangeRowsPerPage(e);
        }}
        // ActionsComponent={TablePaginationActions}
      />
    </div>
  );
};

Pagination.propTypes = {};

export default Pagination;
