import React from "react";
import styled from "styled-components";
import { Tooltip } from "@mui/material";

const Container = styled.div`
  height: ${({ collapse }) => (collapse ? "32px" : "75px")};
  width: ${({ collapse }) => (collapse ? "32px" : "75px")};
  border-radius: 50%;
  background: white;
  font-size: ${({ collapse }) => (collapse ? "1rem" : "2rem")};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.25s;
  color: ${({ color }) => color};
  // box-shadow: 0 0 12px rgba(0, 0, 0, 0.05);
  // border: 1px solid rgb(236, 239, 241);
  border: 1px solid rgba(34, 27, 35, 0.1);
  box-shadow: rgb(17 51 83 / 2%) 0px 4px 12px 0px;
`;

const CollapsibleIcon = ({ collapse, color, tooltip, children, ...props }) => {
  return (
    <Tooltip title={collapse && tooltip ? tooltip : ""}>
      <Container collapse={collapse} color={color} {...props}>
        {children}
      </Container>
    </Tooltip>
  );
};

CollapsibleIcon.propTypes = {};

export default CollapsibleIcon;
