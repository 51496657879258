import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import SimpleBar from "simplebar-react";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import {
  // RiShieldKeyholeLine,
  // RiBookOpenLine,
  // RiRoadMapLine,
  RiClipboardFill,
} from "react-icons/ri";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { FaBoxes, FaTasks } from "react-icons/fa";
import { HiDocumentReport } from "react-icons/hi";
// import { GoDashboard } from "react-icons/go";
import { BiBuildingHouse } from "react-icons/bi";
import { BsArrowBarRight } from "react-icons/bs";
import { RiSettings3Fill } from "react-icons/ri";

import { withPermissions } from "../../providers";
import { hasPermission } from "../../utility";
import Support from "features/Support";
import { ReactComponent as IconHub } from "assets/images/icons/icon-hub.svg";
import { ReactComponent as IconJob } from "assets/images/icons/icon-job.svg";
import { ReactComponent as IconProvider } from "assets/images/icons/icon-provider.svg";
import { ReactComponent as IconCustomer } from "assets/images/icons/icon-customer.svg";
import { ReactComponent as IconForm } from "assets/images/icons/icon-form.svg";
import { ReactComponent as IconMap } from "assets/images/icons/icon-map.svg";

import "./Sidebar.scss";

const phxClientNav = [
  { url: "/hub", icon: <IconHub />, text: "Hub" },
  // { url: "/dashboard", icon: <GoDashboard />, text: "Dashboard" },
  { url: "/jobs", icon: <IconJob />, text: "Jobs" },
  { url: "/members", icon: <IconProvider />, text: "Providers" },
  { url: "/customers", icon: <IconCustomer />, text: "Customers" },
  { url: "/tasks", icon: <FaTasks />, text: "Schedule" },
  { url: "/compliance-manager", icon: <RiClipboardFill />, text: "Compliance" },
  { url: "/reports", icon: <HiDocumentReport />, text: "Reports" },
  { url: "/forms", icon: <IconForm />, text: "Forms" },
  { url: "/map", icon: <IconMap />, text: "Map" },
  { url: "/inventory", icon: <FaBoxes />, text: "Inventory" },
  { url: "/users", icon: <PeopleAltIcon />, text: "Users" },
  { url: "/settings", icon: <RiSettings3Fill />, text: "Settings" },
  // {
  //   url: "https://helixco.zendesk.com",
  //   icon: <RiBookOpenLine />,
  //   text: "Guides",
  //   external: true,
  // },
];

const memberAdminNav = [
  { url: "/hub", icon: <IconHub />, text: "Hub" },
  // { url: "/dashboard", icon: <GoDashboard />, text: "Dashboard" },
  { url: "/jobs", icon: <IconJob />, text: "Jobs" },
  { url: "/members", icon: <IconProvider />, text: "Providers" },
  { url: "/customers", icon: <IconCustomer />, text: "Customers" },
  { url: "/tasks", icon: <FaTasks />, text: "Schedule" },
  { url: "/forms", icon: <IconForm />, text: "Forms" },
  { url: "/map", icon: <IconMap />, text: "Map" },
  { url: "/inventory", icon: <FaBoxes />, text: "Inventory" },
  { url: "/users", icon: <PeopleAltIcon />, text: "Users" },
  // {
  //   url: "https://helixco.zendesk.com",
  //   icon: <RiBookOpenLine />,
  //   text: "Guides",
  //   external: true,
  // },
];

const memberFieldNav = [
  { url: "/hub", icon: <IconHub />, text: "Hub" },
  // { url: "/dashboard", icon: <GoDashboard />, text: "Dashboard" },
  { url: "/jobs", icon: <IconJob />, text: "Jobs" },
  { url: "/members", icon: <IconProvider />, text: "Providers" },
  { url: "/tasks", icon: <FaTasks />, text: "Schedule" },
  { url: "/map", icon: <IconMap />, text: "Map" },
  // {
  //   url: "https://helixco.zendesk.com",
  //   icon: <RiBookOpenLine />,
  //   text: "Guides",
  //   external: true,
  // },
];

const memberAffiliateNav = [
  { url: "/hub", icon: <IconHub />, text: "Hub" },
  // { url: "/dashboard", icon: <GoDashboard />, text: "Dashboard" },
  { url: "/jobs", icon: <IconJob />, text: "Jobs" },
  { url: "/tasks", icon: <FaTasks />, text: "Schedule" },
  { url: "/map", icon: <IconMap />, text: "Map" },
  // {
  //   url: "https://helixco.zendesk.com",
  //   icon: <RiBookOpenLine />,
  //   text: "Guides",
  //   external: true,
  // },
];

const agentNav = [
  { url: "/jobs", icon: <IconJob />, text: "Jobs" },
  { url: "/customers", icon: <IconCustomer />, text: "Customers" },
  { url: "/tasks", icon: <FaTasks />, text: "Schedule" },
  { url: "/map", icon: <IconMap />, text: "Map" },
  // {
  //   url: "https://helixco.zendesk.com",
  //   icon: <RiBookOpenLine />,
  //   text: "Guides",
  //   external: true,
  // },
];

const defaultNav = [
  // { url: "/hub", icon: <IconHub />, text: "Hub" },
  // { url: "/dashboard", icon: <GoDashboard />, text: "Dashboard" },
  { url: "/jobs", icon: <IconJob />, text: "Jobs" },
  { url: "/tasks", icon: <FaTasks />, text: "Schedule" },
  { url: "/map", icon: <IconMap />, text: "Map" },
  // {
  //   url: "https://helixco.zendesk.com",
  //   icon: <RiBookOpenLine />,
  //   text: "Guides",
  //   external: true,
  // },
];

const matches = [
  {path: '/job/', title: 'Jobs'},
  {path: '/member/', title: 'Providers'},
  {path: '/customer/', title: 'Customers'},
];

const getNav = (roles, processed) => {
  if (
    hasPermission(roles, [
      "admin",
      "phx_sub_admin",
      "phx_client_admin",
      "operations",
      "accounting",
      "compliance_admin",
    ])
  ) {
    return phxClientNav;
  } else if (processed.is_affiliate_member && !processed.is_d2d) {
    return memberAffiliateNav;
  } else if (hasPermission(roles, ["member_admin_agent", "member_estimator"])) {
    return memberAdminNav;
  } else if (hasPermission(roles, ["member_field_agent"])) {
    return memberFieldNav;
  } else if (hasPermission(roles, ["mby_agent"])) {
    return agentNav;
  } else {
    return defaultNav;
  }
};

const Sidebar = ({ roles }) => {
  const processed = useSelector((state) => state.auth.user.data._processed);
  const isMediumScreen = useSelector(
    (state) => state.browser.lessThan.extraLarge
  );
  const [state, setState] = useState({
    isCollapsed: true,
  });

  const toggleCollapse = () => {
    setState({
      ...state,
      isCollapsed: !state.isCollapsed,
    });
  };

  const isActive = (match, pathname, title) => {
    if(match){
      return true;
    }
    else{
      let found = false;
      matches.forEach((match) => {
        if(pathname.includes(match.path)){
          if(title === match.title){
            found = true;
          }
        }
      });
      if(found){
        return true;
      }
    }
    return false;
  }

  return (
    <aside
      className={
        state.isCollapsed || isMediumScreen ? "sidebar is-collapsed" : "sidebar"
      }
    >

      {!isMediumScreen && (
        <div className="sidebar__toggle" onClick={toggleCollapse}>
          {/* <img src={collapse} alt="Collapse Sidebar" /> */}
          <BsArrowBarRight />
        </div>
      )}
      <SimpleBar>
        <nav className="sidebar__nav-wrapper">
          <ul
            className={
              state.isCollapsed || isMediumScreen
                ? "sidebar__nav is-collapsed"
                : "sidebar__nav"
            }
          >
            {getNav(roles, processed).map((navItem) => {
              return navItem.external ? (
                <li className="sidebar__nav-item" key={navItem.text}>
                  <Tooltip
                    title={navItem.text}
                    placement="right"
                    disableHoverListener={!state.isCollapsed && !isMediumScreen}
                  >
                    <a
                      className="sidebar__nav-item-link"
                      href={navItem.url}
                      target="blank"
                    >
                      {/* <img
                        src={navItem.icon}
                        alt="Dashboard Icon"
                        className="sidebar__nav-icon nav-icon"
                      /> */}
                      <div className="sidebar__nav-icon nav-icon">{navItem.icon}</div>
                      <span className="sidebar__nav-title">{navItem.text}</span>
                    </a>
                  </Tooltip>
                </li>
              ) : (
                <li className="sidebar__nav-item" key={navItem.text}>
                  <Tooltip
                    title={navItem.text}
                    placement="right"
                    disableHoverListener={!state.isCollapsed && !isMediumScreen}
                  >
                    <NavLink
                      activeClassName="is-active"
                      className="sidebar__nav-item-link"
                      to={navItem.url}
                      isActive={(match, location) => { return isActive(match, location.pathname, navItem.text)}}
                    >
                      {/* <img
                        src={navItem.icon}
                        alt="Dashboard Icon"
                        className="sidebar__nav-icon nav-icon"
                      /> */}
                      <div className="sidebar__nav-icon nav-icon">{navItem.icon}</div>
                      <span className="sidebar__nav-title">{navItem.text}</span>
                    </NavLink>
                  </Tooltip>
                </li>
              );
            })}
            {Boolean(processed.company_alias) && (
              <li className="sidebar__nav-item">
                <Tooltip
                  title="My Company"
                  placement="right"
                  disableHoverListener={!state.isCollapsed && !isMediumScreen}
                >
                  <NavLink
                    activeClassName="is-active"
                    className="sidebar__nav-item-link"
                    to={processed.company_alias}
                    isActive={(match, location) => { return isActive(match, location.pathname, 'My Company')}}
                  >
                    {/* <img
                      src={company}
                      alt="Dashboard Icon"
                      className="sidebar__nav-icon nav-icon"
                    /> */}
                    <div className="sidebar__nav-icon nav-icon">
                      <BiBuildingHouse />
                    </div>
                    <span className="sidebar__nav-title">My Company</span>
                  </NavLink>
                </Tooltip>
              </li>
            )}
            {Boolean(processed.residence_alias) && (
              <li className="sidebar__nav-item">
                <Tooltip
                  title="My Residence"
                  placement="right"
                  disableHoverListener={!state.isCollapsed && !isMediumScreen}
                >
                  <NavLink
                    activeClassName="is-active"
                    className="sidebar__nav-item-link"
                    to={processed.residence_alias}
                    isActive={(match, location) => { return isActive(match, location.pathname, 'My Residence')}}
                  >
                    {/* <img
                      src={company}
                      alt="Dashboard Icon"
                      className="sidebar__nav-icon nav-icon"
                    /> */}
                    <div className="sidebar__nav-icon nav-icon">
                      <BiBuildingHouse />
                    </div>
                    <span className="sidebar__nav-title">My Residence</span>
                  </NavLink>
                </Tooltip>
              </li>
            )}
            <Support>
              <li className="sidebar__nav-item">
                <Tooltip
                  title="Support"
                  placement="right"
                  disableHoverListener={!state.isCollapsed && !isMediumScreen}
                >
                  <div
                    className="sidebar__nav-item-link"
                    // onClick={() => {
                    //   window.zE("webWidget", "open");
                    // }}
                  >
                    <div className="sidebar__nav-icon nav-icon">
                      <ContactSupportIcon />
                    </div>
                    <span className="sidebar__nav-title">Support</span>
                  </div>
                </Tooltip>
              </li>
            </Support>
          </ul>
        </nav>
      </SimpleBar>
    </aside>
  );
};

export default withPermissions(Sidebar);
