import React, { useState } from "react";
import { useSelector } from "react-redux";

import { SummaryCardSkeleton } from "components/Skeletons";
import Card, { CardHeader, CardContent } from "common/Card";
import constants from "components/constants";
import { formatEquipmentDiscountLine } from "components/TMTerms/utils";
import {
  Typography,
  Grid,
  // Collapse,
  Tooltip,
  IconButton,
} from "@mui/material";
import styled from "styled-components";
import { formatMoney, formatPrecision } from "utility";
import { /*ExpandMore, ExpandLess,*/ MoreHoriz, OpenInNew } from "@mui/icons-material";
import CreateEquipment from "features/Equipment/forms/CreateEquipment";
import LaborMenu from "features/Service/forms/ActionsMenu";

import { DefaultLoader } from "common/Loader";
import Dialog from "common/Dialog";
import ExpenseAddEdit from "features/Expense/forms/ExpenseAddEdit";
import Guard from "common/Guard";
// import useMediaQuery from '@mui/material/useMediaQuery';

const Styles = styled.div`
  .summary-card {
    &__divider{
      border-top: 1px solid var(--color-gray-medium);
      margin-top: 0.5rem;
      padding-top: 0.5rem;
      font-weight: 400;

      &.no-heading{ font-weight: 300; }
    },

    &__totals{
      &__card-header{
        h6{
          text-transform: none;
        }

      }
    }

    &__body{
      // & > div {
      //   text-transform: uppercase;
      //   font-weight: 600;
      //   font-size: 0.85rem;
      //   color: rgba(0, 0, 0, 0.8);
      //   margin-bottom: 0.5rem;
      // }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        font-weight: 300;

        li {
          display: flex;

          span {
            flex: 1;
          }
        }
      }
    }

    &__sub-summary{
      &__card-header{
        h6{
          display: flex;
          justify-content: space-between;
          width: 100%;
          text-transform: none;
        }

        &__view-more{
          padding-left: 0.5em;
        }

        &__add-button{
          padding: 0.25em 0.5em !important;
          border-radius: ${constants.borderRadius};
          background-color: var(--color-blue);
          color: white;
          width: auto;

          &__labor{ min-width: 130px; }

          &.expanded{
            border-radius: ${constants.borderRadius} ${constants.borderRadius} 0 0;
          }

          + * .summary-card__sub-summary__card-header__add-button{
            border-radius: 0;
            border-top: 1px solid var(--color-gray-medium);

            &:last-child{
              border-radius: 0 0 ${constants.borderRadius} ${constants.borderRadius};
            }
          }

          .MuiListItemIcon-root{
            min-width: 0;
            padding-right: 0.25em;
            color: white;
          }

          .MuiListItemText-root{ margin: 0; }

          &:hover{
            background-color: var(--color-blue-dark);
            color: white;

            .MuiListItemIcon-root{
              color: white;
            }
          }
        }
      }
      ul{
        > li{
          padding-bottom: 0.25rem;
          border-bottom: 1px solid var(--color-gray-medium);
          margin-bottom: 0.25rem;

          > * > *{
            // flex: 0 1 33%;
            font-size: 0.875rem;
            &.card__right{
              text-align: left;
            }

            label{
              font-style: italic;
            }
          }
          &.labor-item > *:first-child{
            font-size: 1em;
          }
        }
        > li:last-child{
          border: none;
        }
      }
      &__labor-item,
      &__equipment-item{
        text-transform: none;
      }
      &__labor-item .name{
        font-size: 1em;
      }

      &__equipment-item{
        .category > * + *{
          padding-left: 0.5rem;
        }
      }

      &__expand-footer{
        justify-content: flex-end;
        padding: 0;

        &__more-icon{
          text-align: right;
        }
      }
    }
  }
`;

const SummaryCard = ({
  title,
  division,
  totals,
  labor,
  equipment,
  expenses,
  // separateCategories,
  total,
  invisibleTotal,
  billed,
  notBilled,
  discount,
  discount_amt,
  total_w_discount,
  billed_discount,
  billed_total_w_discount,
  not_billed_discount,
  not_billed_total_w_discount,
  serviceTotals,
  equipmentTotals,
  expenseTotals,
  separateCategoryTotals,
  loading,
}) => {
  const [expand, setExpand] = useState({});
  const separateCategories = useSelector((state) => state.taxonomies.separate_category_options);
  const clientTitle = useSelector((state) => state.job.data.field_phoenix_client.title)

  let colLayout = {
    left: [],
    right: [],
  };
  let column = 'right';
  //if(serviceTotals && serviceTotals.length > 0){
    colLayout[column].push('service');
    column = (column === 'left' ? 'right' : 'left');
  //}
  //if(equipmentTotals && equipmentTotals.length > 0){
    colLayout[column].push('equipment');
    column = (column === 'left' ? 'right' : 'left');
  //}
  //if(expenseTotals && expenseTotals.length > 0){
    colLayout[column].push('expense');
    column = (column === 'left' ? 'right' : 'left');
  //}
  for(let category in separateCategoryTotals){
    if(separateCategoryTotals[category].length > 0){
      colLayout[column].push(category);
      column = (column === 'left' ? 'right' : 'left');
    }
  }

  const getItemTotals = ((type) => {
    switch(type){
      case 'service':
        return serviceTotals;
      case 'equipment':
        return equipmentTotals;
      case 'expense':
        return expenseTotals;
      default:
        return separateCategoryTotals[type];
    }
  });

  // const largeScreen = useMediaQuery(theme => theme.breakpoints.up('md'));

  return (
    <Styles>
    <Grid container spacing={2} className="flex-col grid-md:flex-row">
      <Grid item xxs={12} md={6}>
        <Card className="summary-card summary-card__totals">
          <CardHeader title={title} className="summary-card__totals__card-header"></CardHeader>
          <CardContent>
            {loading ? (
              <div>
                <SummaryCardSkeleton />
              </div>
            ) : (
              <div className="summary-card__body">
                <ul>
                  <li>
                    <span>Labor:</span>${labor}
                  </li>
                  <li>
                    <span>Equipment:</span>${equipment}
                  </li>
                  {separateCategories.resources
                    .filter((cat) => Number(totals[`sep_cat_${cat.tid}`]) > 0)
                    .map((cat) =>
                    <li key={`eqp_sep_cat${cat.tid}`}>
                      <span>{cat.name}:</span>{formatMoney(totals[`sep_cat_${cat.tid}`])}
                    </li>
                  )}
                  <li>
                    <span>Expenses:</span>${expenses}
                  </li>
                  {/* {separateCategories && separateCategories.map((category, key) => (
                    <li key={key}>
                      <span>{category.term}:</span> ${category.total}
                    </li>
                  ))} */}
                  {separateCategories.expense_types
                    .filter((cat) => Number(totals[`sep_cat_${cat.tid}`]) > 0)
                    .map((cat) =>
                    <li key={`exp_sep_cat${cat.tid}`}>
                      <span>{cat.name}:</span>{formatMoney(totals[`sep_cat_${cat.tid}`])}
                    </li>
                  )}
                  <li className="summary-card__divider" >
                    <span>Total:</span>${total}
                  </li>
                  <Guard customOverrideName="canManageEntryVisibility">
                    <li>
                      <span>{clientTitle + ' '} entries</span>${invisibleTotal}
                    </li>
                  </Guard>
                  <li>
                    <span>Billed/Not Billed:</span>${billed}/ ${notBilled}
                  </li>
                  {discount && (
                    <>
                    <li className="summary-card__divider" >
                      <span>Equipment Discount Terms:</span>
                      <div style={{textAlign: `right`, }}>
                        {discount?.equipment?.weekly && (
                          <Typography
                            variant="subtitle2"
                            color="textSecondary"
                            style={{ lineHeight: `1rem`,}}
                          >
                            WEEKLY RATE - &#40;{formatEquipmentDiscountLine(discount?.equipment?.weekly)}&#41;
                          </Typography>
                        )}
                        {discount?.equipment?.monthly && (
                          <Typography
                            variant="subtitle2"
                            color="textSecondary"
                            style={{ lineHeight: `1rem`,}}
                          >
                            MONTHLY RATE - &#40;{formatEquipmentDiscountLine(discount?.equipment?.monthly)}&#41;
                          </Typography>
                        )}
                      </div>
                    </li>
                    {discount_amt && (
                      <>
                      <li style={{justifyContent: `flex-end`, marginTop: "0.5rem", }}>-${discount_amt}</li>
                      <li>
                        <span>Total w/ Terms:</span>
                        <div>${total_w_discount}</div>
                      </li>
                      <li style={{justifyContent: `flex-end`, marginTop: "0.5rem", }}><div>-${billed_discount}/ -${not_billed_discount}</div></li>
                      <li>
                        <span>Billed/Not Billed w/ Terms:</span>
                        <div>${billed_total_w_discount}/ ${not_billed_total_w_discount}</div>
                      </li>
                      </>
                    )}
                    </>
                  )}
                  {(division?._processed?.ds_cost_access && totals?.total_cost > 0) && (
                    <>
                    {(totals?.services_cost) && (
                      <>
                      <li className="summary-card__divider no-heading" >
                        <span>Labor Cost:</span>
                        <div>${totals.services_cost}</div>
                      </li>
                      <li>
                        <span>Labor Profit:</span>
                        <div>{`$${totals.services_profit} (${formatPrecision(totals.services_profit_margin)}%)`}</div>
                      </li>
                      </>
                    )}
                    {(totals?.equipment_cost) && (
                      <>
                      <li className="summary-card__divider no-heading" >
                        <span>Equipment Cost:</span>
                        <div>${totals.equipment_cost}</div>
                      </li>
                      <li>
                        <span>Equipment Profit:</span>
                        <div>{`$${totals.equipment_profit} (${formatPrecision(totals.equipment_profit_margin)}%)`}</div>
                      </li>
                      </>
                    )}
                    {separateCategories.resources.map((cat) => {
                      if(Number(totals[`sep_cat_${cat.tid}_cost`]) > 0){
                        return (<>
                        <li key={`eqp_sep_cat${cat.tid}_cost`} className="summary-card__divider no-heading" >
                          <span>{`${cat.name} Cost:`}</span>
                          <div>{formatMoney(totals[`sep_cat_${cat.tid}_cost`])}</div>
                        </li>
                        <li key={`eqp_sep_cat${cat.tid}_profit`}>
                          <span>{`${cat.name} Profit:`}</span>
                          <div>{`${formatMoney(totals[`sep_cat_${cat.tid}_profit`])} (${formatPrecision(totals[`sep_cat_${cat.tid}_profit_margin`])}%)`}</div>
                        </li>
                        </>);
                      }
                      return (<></>);
                    })}
                    {/* {(totals?.sep_cat_equipment_cost) && (
                      <>
                      <li className="summary-card__divider no-heading" >
                        <span>Consumables & Materials Cost:</span>
                        <div>${totals.sep_cat_equipment_cost}</div>
                      </li>
                      <li>
                        <span>Consumables & Materials Profit:</span>
                        <div>{`$${totals.sep_cat_equipment_profit} (${totals.equipment_profit_margin}%)`}</div>
                      </li>
                      </>
                    )} */}
                    {(totals?.expenses_cost) && (
                      <>
                      <li className="summary-card__divider no-heading" >
                        <span>Expenses:</span>
                        <div>${totals.expenses_cost}</div>
                      </li>
                      {totals?.expenses_markup > 0 && (
                        <li>
                          <span>Expenses Markup:</span>
                          <div>{`$${totals.expenses_markup} (${formatPrecision(totals.expenses_markup_percent)}%)`}</div>
                        </li>
                      )}
                      </>
                    )}
                    {separateCategories.expense_types.map((cat) => {
                      if(Number(totals[`sep_cat_${cat.tid}_cost`]) > 0){
                        return (<>
                        <li key={`exp_sep_cat${cat.tid}_cost`} className="summary-card__divider no-heading" >
                          <span>{`${cat.name} Cost:`}</span>
                          <div>{formatMoney(totals[`sep_cat_${cat.tid}_cost`])}</div>
                        </li>
                        {totals?.[`sep_cat_${cat.tid}_markup`] > 0 && (
                          <li key={`exp_sep_cat${cat.tid}_markup`}>
                            <span>{`${cat.name} Markup:`}</span>
                            <div>{`${formatMoney(totals[`sep_cat_${cat.tid}_markup`])} (${formatPrecision(totals[`sep_cat_${cat.tid}_markup_percent`])}%)`}</div>
                          </li>
                        )}
                        </>);
                      }
                      return (<></>);
                    })}
                    {/* {(totals?.sep_cat_expenses_cost) && (
                      <>
                      <li className="summary-card__divider no-heading" >
                        <span>Vendors/Subcontractors:</span>
                        <div>${totals.sep_cat_expenses_cost}</div>
                      </li>
                      {totals?.sep_cat_expenses_markup > 0 && (
                        <li>
                          <span>Vendors/Subcontractors Markup:</span>
                          <div>{`$${totals.sep_cat_expenses_markup} (${totals.sep_cat_expenses_markup_percent}%)`}</div>
                        </li>
                      )}
                      </>
                    )} */}
                    {(totals?.total_cost) && (
                      <>
                      <li className="summary-card__divider" >
                        <span>Total Cost:</span>
                        <div>${totals.total_cost}</div>
                      </li>
                      <li>
                        <span>Total Profit:</span>
                        <div>{`$${totals.total_profit} (${formatPrecision(totals.total_profit_margin)}%)`}</div>
                      </li>
                      </>
                    )}
                    </>
                  )}
                </ul>
              </div>
            )}
          </CardContent>
        </Card>
        {colLayout.left.map((summary) => (
          <ItemSummary
            loading={loading}
            type={summary}
            itemTotals={getItemTotals(summary)}
            expand={expand}
            setExpand={setExpand}
            key={`left${summary}`}
          />
        ))}
      </Grid>
      <Grid item xxs={12} md={6}>
        {colLayout.right.map((summary) => (
          <ItemSummary
            loading={loading}
            type={summary}
            itemTotals={getItemTotals(summary)}
            expand={expand}
            setExpand={setExpand}
            key={`right${summary}`}
          />
        ))}
      </Grid>
      </Grid>
    </Styles>
  );
};

const ItemSummary = ({
  loading,
  type,
  itemTotals,
  expand,
  setExpand,
}) => {
  const viewMoreThreshold = 5;
  const previewItemTotals = (itemTotals ? Object.entries(itemTotals).filter(([inc]) => Number(inc) < viewMoreThreshold).map((entry) => entry[1]) : []);
  const allItemTotals = (itemTotals ? Object.entries(itemTotals).map((entry) => entry[1]) : []);

  let title = '';
  switch(type){
    case 'service':
      title = 'Labor Summary';
    break;

    case 'equipment':
      title = 'Equipment Summary';
    break;

    case 'expense':
      title = 'Expense Summary';
    break;

    default:
      title = type + ' Summary';
    break;
  }

  const getTotalRow = ((type, itemTotal) => {
    switch(type){
      case 'service':
        const laborType = (itemTotal?.labor_type ? itemTotal.labor_type.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() : 'other');
        const serviceKey = itemTotal.user_id + '_' + laborType;
        return <ServiceTotalRow serviceTotal={itemTotal} key={serviceKey} />

      case 'equipment':
        return <EquipmentTotalRow equipmentTotal={itemTotal} key={itemTotal.chase} />

      case 'expense':
        return <ExpenseTotalRow expenseTotal={itemTotal} key={itemTotal.name} />

      default:
        return <SeparateCategoryTotalRow itemTotal={itemTotal} key={itemTotal.group_key} />
    }
  });

  const addButton = ((type) => {
    switch(type){
      case 'service':
        return (<div key={`add_button_${type}`} ><LaborMenu fromSummaryCard={true} className="summary-card__sub-summary__card-header__add-button summary-card__sub-summary__card-header__add-button__labor" /></div>);

      case 'equipment':
        return <CreateEquipment fromSummaryCard={true} className="summary-card__sub-summary__card-header__add-button" key={`add_button_${type}`} />

      case 'expense':
        return <ExpenseAddEdit fromSummaryCard={true} className="summary-card__sub-summary__card-header__add-button" key={`add_button_${type}`} />

      default:
        return null;
    }
  });

  return (
    !loading && (
    <>
      <Card className="summary-card summary-card__sub-summary">
        <CardHeader
          className="summary-card__sub-summary__card-header"
          title={
            <>
              <div>
              {title}
                {allItemTotals.length > previewItemTotals.length && (
                <Tooltip className="summary-card__sub-summary__card-header__view-more" title="View More">
                  <IconButton
                    size="small"
                    onClick={(e) => {setExpand({...expand, [type]: !expand?.[type]})}}
                  >
                    <OpenInNew />
                  </IconButton>
                </Tooltip>
                )}
              </div>
              {addButton(type)}
            </>
          }
        />
        {previewItemTotals.length > 0 && (
        <CardContent>
          <div className="summary-card__body">
            <ul>
            {previewItemTotals.map((itemTotal) => getTotalRow(type, itemTotal))}
            {allItemTotals.length > previewItemTotals.length && (
              <li className="summary-card__sub-summary__expand-footer">
                <div className="summary-card__sub-summary__expand-footer__more-icon">
                  <Tooltip title="View More">
                    <IconButton
                      size="small"
                      onClick={(e) => {setExpand({...expand, [type]: !expand?.[type]})}}
                    >
                      <MoreHoriz className="summary-card__expand-footer__icon" />
                    </IconButton>
                  </Tooltip>
                </div>
              </li>
            )}
            </ul>
          </div>
        </CardContent>
        )}
      </Card>
      {allItemTotals.length > 0 && (
        <Dialog
          open={(expand?.[type] ? true : false)}
          onClose={(e) => {setExpand({...expand, [type]: !expand?.[type]})}}
          title={title}
          initialValues={{}}
        >
          <Styles>
            {loading ? (
              <div>
                <DefaultLoader />
              </div>
            ) : (
              <Card className="summary-card summary-card__sub-summary">
                <CardContent>
                  <div className="summary-card__body">
                    <ul>
                      {allItemTotals.map((itemTotal) => getTotalRow(type, itemTotal))}
                    </ul>
                  </div>
                </CardContent>
              </Card>
            )}
          </Styles>
        </Dialog>
      )}
    </>
  )
  );
};

export const ServiceTotalRow = ({serviceTotal, totalsOnly}) => {
  const totals = (
    <>
    {serviceTotal?.standard_hours > 0 && (
      <div>
        <label>Standard Hours:</label> {serviceTotal?.standard_hours} / {formatMoney(serviceTotal?.standard_total)}
      </div>
    )}
    {serviceTotal?.travel_hours > 0 && (
      <div>
        <label>Travel Hours:</label> {serviceTotal?.travel_hours} / {formatMoney(serviceTotal?.travel_total)}
      </div>
    )}
    {serviceTotal?.after_hours_hours > 0 && (
      <div>
        <label>After Hours:</label> {serviceTotal?.after_hours_hours} / {formatMoney(serviceTotal?.after_hours_total)}
      </div>
    )}
    {serviceTotal?.overtime_hours > 0 && (
      <div>
        <label>Overtime:</label> {serviceTotal?.overtime_hours} / {formatMoney(serviceTotal?.overtime_total)}
      </div>
    )}
    {serviceTotal?.per_diem_total > 0 && (
      <div>
        <label>Per Diem:</label> {formatMoney(serviceTotal.per_diem_total)}
      </div>
    )}
    {serviceTotal?.ppe_total > 0 && (
      <div>
        <label>PPE:</label> {formatMoney(serviceTotal.ppe_total)}
      </div>
    )}
    {serviceTotal?.small_tools_charge_total > 0 && (
      <div>
        <label>Small Tools Charge:</label> {formatMoney(serviceTotal.small_tools_charge_total)}
      </div>
    )}
    {serviceTotal?.lodging_total > 0 && (
      <div>
        <label>Lodging:</label> {formatMoney(serviceTotal.lodging_total)}
      </div>
    )}
    {serviceTotal?.markup_total > 0 && (
      <div>
        <label>Markup:</label> {formatMoney(serviceTotal.markup_total)}
        {serviceTotal.markup_percent_total > 0 && ` (${serviceTotal.markup_percent_total}%)`}
      </div>
    )}
    <div>
      <label>Total:</label> {formatMoney(serviceTotal?.total)}
    </div>
    </>
  );

  if(totalsOnly){
    return totals;
  }

  return (
    <li className="summary-card__sub-summary__labor-item">
      <Grid container spacing={2}>
        {Boolean(serviceTotal.user_name) ? (
          <>
          <Grid item xxs={4} className="name">{serviceTotal.user_name}</Grid>
          <Grid item xxs={4}>{serviceTotal.labor_type}</Grid>
          </>
        ) : (
          <Grid item xxs={6} className="name">{serviceTotal.labor_type}</Grid>
        )}
        <Grid item xxs={4} className="card__right">
          {totals}
        </Grid>
      </Grid>
    </li>
  )
};

const EquipmentTotalRow = ({equipmentTotal}) => {
  return (
    <li className="summary-card__sub-summary__equipment-item">
      <Grid container spacing={2}>
        <Grid item xxs={6} xs={4} className="category">
          <div>{equipmentTotal.category}</div>
          <div>{equipmentTotal.sub_category}</div>
        </Grid>
        <Grid item xxs={6} xs={4}>{equipmentTotal.name}</Grid>
        <Grid item xxs={6} xs={2}>{new Intl.NumberFormat('en-US').format(equipmentTotal.total_qty)} {equipmentTotal.increment}</Grid>
        <Grid item xxs={6} xs={2} className="card__right">
          {/* {Number(equipmentTotal?.markup) > 0 && (
            <div>
              <label>Markup:</label> {formatMoney(equipmentTotal?.markup)}
            </div>
          )} */}
          <div>
            <label>{(equipmentTotal?.discount?.total_diff > 0 ? `Subt` : `T`)}otal:</label> {formatMoney(equipmentTotal?.total)}
          </div>
          {equipmentTotal?.discount?.weekly > 0 && (
            <div>
              <label>Weekly Rates:</label> -{formatMoney(equipmentTotal.discount.weekly_total_diff)}
            </div>
          )}
          {equipmentTotal?.discount?.monthly > 0 && (
            <div>
              <label>Monthly Rates:</label> -{formatMoney(equipmentTotal.discount.monthly_total_diff)}
            </div>
          )}
          {equipmentTotal?.discount?.total > 0 && (
            <div>
              <label>Total:</label> {formatMoney(equipmentTotal.discount.total)}
            </div>
          )}
        </Grid>
      </Grid>
    </li>
  )
};

const ExpenseTotalRow = ({expenseTotal}) => {
  return (
    <li className="summary-card__sub-summary__expense-item">
      <Grid container spacing={2}>
        <Grid item xxs={9}>{expenseTotal.name}</Grid>
        <Grid item xxs={3} className="card__right">
          <div>
            <label>Total:</label> {formatMoney(expenseTotal.total)}
          </div>
        </Grid>
      </Grid>
    </li>
  )
};

const SeparateCategoryTotalRow = ({itemTotal}) => {
  const hasCategory = (itemTotal.sub_category_name !== '');
  const hasQty = (itemTotal.total_qty && Number(itemTotal.total_qty) > 0);
  const nameCols = 5 + (!hasCategory ? 3 : 0) + (!hasQty ? 2 : 0);

  return (
    <li className="summary-card__sub-summary__separate_cat-item">
      <Grid container spacing={2}>
        {hasCategory && (
          <Grid item xxs={6} xs={3}>{itemTotal.sub_category_name}</Grid>
        )}
        <Grid item xxs={6} xs={nameCols}>{itemTotal.sub_name}</Grid>
        {hasQty &&
          <Grid item xxs={6} xs={2}>{new Intl.NumberFormat('en-US').format(itemTotal.total_qty)} {itemTotal.increment}</Grid>
        }
        <Grid item xxs={6} xs={2} className="card__right">
          <div>
            <label>Total:</label> {formatMoney(itemTotal.total)}
          </div>
        </Grid>
      </Grid>
    </li>
  )
};

SummaryCard.propTypes = {};

export default SummaryCard;
