import axios from "axios";
import querystring from "querystring";

import action from "../action";
import config from "../../config";
import { tokenConfig } from "../authActions";

export const processBatch = (url, params, method) =>
  action("batch", config.api_url, url, params, method);

const BATCH_START = "export/BATCH_START";
const BATCH_OK = "export/BATCH_OK";
const BATCH_ERROR = "export/BATCH_ERROR";
const BATCH_CLEAR = "export/BATCH_CLEAR";

export const batchActions = {
  BATCH_START,
  BATCH_OK,
  BATCH_ERROR,
  BATCH_CLEAR,
};

export const exportBatchStart = () => ({
  type: BATCH_START,
});

export const exportBatchOk = (payload, params, method) => ({
  type: BATCH_OK,
  payload,
  params,
  method,
});

export const exportBatchError = (payload) => ({
  type: BATCH_ERROR,
  payload,
});

export const exportBatchClear = (id) => ({
  type: BATCH_CLEAR,
  id,
});

export const exportBatch = (path, params, method) => async (dispatch, getState) => {
  let url = `${config.api_url}${path}`;

  if (params && (!method || method === 'GET' || params === "string")) {
    url = `${url}?${
      typeof params === "string"
        ? params.substring(1)
        : querystring.stringify(params)
    }`;
  }

  dispatch(exportBatchStart());

  const getData = async () => {
    try {
      let response = null;
      if(!method || method === 'GET'){
        response = await axios.get(url, {
          ...tokenConfig(getState),
        });
      }
      else if(method === 'POST'){
        response = await axios.post(url, params, {
          ...tokenConfig(getState),
        });
      }
      else if(method === 'PATCH'){
        response = await axios.patch(url, params, {
          ...tokenConfig(getState),
        });
      }

      dispatch(exportBatchOk(
        response.data,
        method && method !== 'GET' ? params : null,
        method
      ));
      return response;
    } catch (err) {
      const requestUrl = new URL(url);
      const batchId = requestUrl.searchParams.get("batch_id");
      dispatch(exportBatchError({ batch_id: batchId, error: err }));
      return err;
    }
  };

  return getData();
};
