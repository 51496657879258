
import React, { useState } from "react";
import { useSelector } from "react-redux";

import Avatar from "common/Avatar";
import {
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import TimeClockDialog from "../TimeClockDialog";
import TimeCardLineItem from "./TimeCardLineItem";
import { BsArrowRight } from "react-icons/bs";
import { size } from "lodash";
import Dialog from "common/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import ServiceAddEdit from "features/Service/forms/ServiceAddEdit";
import { ServiceTotalRow } from "features/DailySheets/components/SummaryCard";
import { AiOutlineFileAdd } from "react-icons/ai";
import { OpenInNew } from "@mui/icons-material";
import { ReactComponent as TimeCardStrikeIcon } from "assets/images/icons/icon-time-card-strike.svg";
import { ReactComponent as TimeCardIcon } from "assets/images/icons/icon-time-card.svg";
import { ReactComponent as IconUserAccount } from "assets/images/icons/icon-user-account.svg";
import { ReactComponent as IconUserNonAccount } from "assets/images/icons/icon-user-non-account.svg";
import { ImFilesEmpty } from "react-icons/im";
import "../styles/TimeCards.scss";

const TimeCardUser = ({
  user,
  division=null,
  summary,
  onUserClose,
  isUserSummary,
  handleRefresh,
  cardPreviewQty=4,
  showQtys=false,
  clickOverride,
  onClick,
}) => {
  const [open, setOpen] = useState(false);
  const timeClockActive = (size(division) ? division?._processed?.time_clock_active : false);
  const timeClockManager = (timeClockActive && division?._processed?.time_clock_manage && !clickOverride);
  const browser = useSelector((state) => state.browser);

  const userDetailClose = () => {
    setOpen(false);
    if(handleRefresh){
      handleRefresh();
    }
  };

  return (
    <>
      <div className={`time-clock card-user ${!summary ? 'detail' : ''}`} onClick={onClick}>
        {!isUserSummary &&
        <div
          className={`user-heading ${summary && !clickOverride ? 'summary' : ''}`}
          // onClick={(e) => {
          //   if(summary && !clickOverride){
          //     setOpen(true);
          //   }
          // }}
        >
          <div className="avatar">
            <Avatar
              title={`${user?.ds_user_full_name}`}
              link={user._files?.user_profile_pic_medium ? user._files?.user_profile_pic_medium : null}
              size={browser.is.medium || browser.lessThan.medium || clickOverride ? "medium" : "large"}
            />
          </div>
          <div className="info">
            <div className="info-heading">
              <Typography variant="h5">{user.ds_user_full_name}</Typography>
              {timeClockManager &&
                <div
                  className="manual-entry-add"
                  onClick={(e) => e.stopPropagation()}
                >
                  <ServiceAddEdit
                    handleRefresh={handleRefresh}
                    icon={
                      <Tooltip title="Add Labor Entry">
                        <IconButton size="medium" edge={false}>
                          <AiOutlineFileAdd />
                        </IconButton>
                      </Tooltip>
                    }
                    initUser={{
                      name: user.ds_user_full_name,
                      entity_id: user.ds_user_uid ? user.ds_user_uid : user?.ds_user_temp_nid,
                      type: user.ds_user_uid ? "user" : "temporary_user",
                      uid: user.ds_user_uid,
                      service_types: (user?.user_service_types ? user.user_service_types : []),
                    }}
                  />
                </div>
              }
              {(summary || clickOverride) ? (
                <div className="detail-open"
                  onClick={(e) => e.stopPropagation()}
                >
                  <OpenInNew className="icon" onClick={() => setOpen(true)} />
                  <Dialog
                    open={open}
                    onClose={userDetailClose}
                  >
                    <TimeCardUser
                      user={user}
                      division={division}
                      onUserClose={userDetailClose}
                      timeClockManager={timeClockManager}
                      handleRefresh={handleRefresh}
                    />
                  </Dialog>
                </div>
              ) : (
                Boolean(onUserClose) &&
                <div className="detail-close" >
                  <IconButton
                    onClick={onUserClose}
                    size="small"
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              )}
            </div>
            <div className="sub-info">
              <div className={`account-type ${size(user.totals) > 0 ? 'has-totals' : ''}`}>
                <IconButton
                  size="small"
                  edge={false}
                  className={`non-mui-icon ${user.ds_user_uid ? 'account-user' : 'non-account-user'}`}
                >
                  {user.ds_user_uid ? (
                    <IconUserAccount />
                  ) : (
                    <IconUserNonAccount />
                  )}
                </IconButton>
                <Typography className="text" variant="subtitle2">{user.ds_user_uid ? `Account User` : `Non-Account User`}</Typography>
              </div>
              {size(user.totals) > 0 &&
                <div className="totals"><ServiceTotalRow serviceTotal={user.totals} totalsOnly /></div>
              }
            </div>
          </div>
        </div>
        }
        {showQtys &&
          <TimeCardUserQtys user={user} />
        }
        <div className="cards">
          {size(user.time_cards) === 0 ? (
            <Typography variant="body1" className="empty-message">
              <TimeCardStrikeIcon className="icon" /> No time Cards
            </Typography>
          ) : (
          user.time_cards
            .filter((card, j) => !summary || j < cardPreviewQty)
            .map((card, j) =>
              <TimeClockDialog
                divisionHash={division ? division.field_public_hash : ''}
                serviceHash={card.hash}
                onClose={() => {
                  if(summary && card.status !== 'complete'){
                    handleRefresh();
                  }
                }}
                key={j}
                disabled={clickOverride}
              >
                <TimeCardLineItem
                  card={card}
                  division={division}
                  summary={summary}
                  handleRefresh={handleRefresh}
                  showAdminButtons={timeClockManager}
                  clickOverride={clickOverride}
                />
              </TimeClockDialog>
          )
        )}
        </div>
        {(!clickOverride && summary && size(user.time_cards) > cardPreviewQty) &&
          <div
            className="more-cards"
            onClick={() => setOpen(true)}
          >
            <span className="text">
              More Time Cards <BsArrowRight className="icon" />
            </span>
          </div>
        }
      </div>
    </>
  );
};


export const TimeCardUserQtys = ({user}) => {
  const cardQty = Number(user.time_card_qty);
  const cardOpenQty = Number(user.time_card_open_qty);
  const jobQty = Number(user.job_division_qty);
  return (
    <div className="card-qtys">
      <Typography variant="subtitle2" className={`qty-line ${!cardQty ? 'none' : ''} ${cardOpenQty > 0 ? 'open' : ''}`}>
        <TimeCardIcon className="icon" /> {cardQty} Time Cards{cardOpenQty > 0 ? ` (${cardOpenQty} Open)` : ''}
      </Typography>
      <Typography variant="subtitle2" className={`qty-line ${!jobQty ? 'none' : ''}`}>
        <ImFilesEmpty className="icon" /> {jobQty} Jobs
      </Typography>
    </div>
  );
}

TimeCardUser.propTypes = {};

export default TimeCardUser;
