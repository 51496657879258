
import React, {  } from "react";
import { size } from "lodash";
import { BsArrowLeftRight, BsFileEarmarkPlus } from "react-icons/bs";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  MenuItem,
  Divider,
} from "@mui/material";
import ButtonLoader from "common/ButtonLoader";
import { SelectFormField } from "common/Fields";


const TimeClockChange = ({
  timeClock,
  isSubmitting,
  getNewTimeClock,
  changeTimeCard,
  setFieldValue,
  values,
  submitForm,
}) => {
  // const [changing, setChanging] = useState(false);

  const timeCards = [];
  const timeCardOptions = [];
  if(size(timeClock.time_card_users)){
    if(size(timeClock.time_card_users?.accounts)){
      timeCards.push(<li key="time_card_users" className="sub-heading">Member Users</li>);
      timeClock.time_card_users.accounts.forEach((user) => {
        const value = 'uid-' + user.uid;
        timeCardOptions.push({
          value: value,
          label: user.name,
        });
        timeCards.push(<MenuItem key={value} value={value}>
          {user.name}
        </MenuItem>);
      });
    }
    if(size(timeClock.time_card_users?.temp_users)){
      timeCards.push(<Divider key="divider"/>)
      timeCards.push(<li key="temp_users" className="sub-heading">Temporary/Non-Account Users</li>);
      timeClock.time_card_users.temp_users.forEach((user) => {
        const value = 'hash-' + user.hash;
        timeCardOptions.push({
          value: value,
          label: user.name,
        });
        timeCards.push(<MenuItem key={`nid-${user.nid}`} value={value}>
          {user.name}
        </MenuItem>);
      });
    }
  }

  if(!timeClock?.service && !size(timeCardOptions)){
    return;
  }

  return (
    <>
    <div className="change-time-card">
      <Button
        variant="contained"
        color="secondary"
        className="new-time-card"
        onClick={() => setFieldValue('selecting_time_card', true)}
      >
        <BsArrowLeftRight className="edit-icon medium" />Change Time Card
      </Button>
    </div>
    <Dialog
      open={values.selecting_time_card}
      // onClose={onClose}
      aria-labelledby="time-clock-change-time-card-dialog-title"
      aria-describedby="time-clock-change-time-card-dialog-description"
      fullWidth
      maxWidth="xs"
      className="time-clock dialog"
    >
      <DialogTitle id="time-clock-change-time-card-dialog-title" className="dialog-title"><BsArrowLeftRight className="edit-icon large" /> Change Time Card</DialogTitle>
      <DialogContent id="time-clock-change-time-card-dialog-description" className="change-time-card-dialog">
        <ButtonLoader
            variant="contained"
            color="primary"
            type="submit"
            isSubmitting={isSubmitting}
            disabled={isSubmitting}
            disableElevation
            size="medium"
            onClick={getNewTimeClock}
          >
            <BsFileEarmarkPlus className="edit-icon medium" /> New Time Card
          </ButtonLoader>
          <div className="or">or</div>
          <SelectFormField
            fullWidth
            name="select_time_card"
            label="Select a Current Time Card"
            margin="normal"
            disabled={isSubmitting || !size(timeCardOptions)}
            isSubmitting={isSubmitting || !size(timeCardOptions)}
            options={timeCardOptions}
            helperText={!size(timeCardOptions) ? <div className="select-helper">There are no other current time cards</div> : null}
            nullable
          >
            {timeCards}
          </SelectFormField>
          <div className="buttons">
            <Button
              variant="outlined"
              onClick={() => {
                setFieldValue('selecting_time_card', false)
                setFieldValue('select_time_card', null);
                // setChanging(false);
              }}
              disabled={isSubmitting}
            >
              Cancel
            </Button>
            <ButtonLoader
              variant="contained"
              color="primary"
              type="submit"
              isSubmitting={isSubmitting}
              disabled={isSubmitting || !size(timeCards)}
              disableElevation
              size="medium"
              onClick={() => {
                // submitForm('change_time_card')
                if(values.select_time_card.indexOf('hash-') >= -1){
                  const hash = values.select_time_card.replace('hash-', '');
                  changeTimeCard(null, hash);
                }
                else if(values.select_time_card.indexOf('uid-') >= -1){
                  const uid = values.select_time_card.replace('uid-', '');
                  changeTimeCard(uid);
                }
              }}
            >
              <BsArrowLeftRight className="edit-icon medium" /> Change
            </ButtonLoader>
          </div>

      </DialogContent>
      {/* <DialogActions>
        <Button onClick={() => setChanging(false)} disabled={isSubmitting}>
          Cancel
        </Button>
        <ButtonLoader
          variant="contained"
          color="primary"
          type="submit"
          isSubmitting={isSubmitting}
          disabled={isSubmitting}
          disableElevation
          size="small"
          onClick={() => {
            submitForm('update_times');
            setOpen(false);
          }}
        >
          Change
        </ButtonLoader>
      </DialogActions> */}
    </Dialog>
    </>
  );
};

TimeClockChange.propTypes = {};

export default TimeClockChange;
