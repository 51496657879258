// TODO: Need to add better error handling and messaging if some items in the batch fail to post

import React, { useCallback, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { Field, FieldArray } from "formik";
import {
  TextField,
  Grid,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import moment from "moment";
import { toArray, size } from "lodash";
import Sticky from "react-sticky-el";
import { AiOutlineFileAdd } from "react-icons/ai";
import { GiArchiveResearch } from "react-icons/gi";

import { CheckboxFormField, DateFormField } from "common/Fields";
import { DateRangeFormField } from "common/Fields";
import { formatMoney, slugify, generateTempUUID, isDivisionStatusPast } from "utility";
import { DialogForm } from "common/Dialog";
// import Tabs, { Tab } from "@phx/common/Tabs";
import {
  BatchContainer,
  EquipmentBatchField,
  EquipmentBatchLabel,
  EquipmentBatchHeader,
} from "./Styled";
import { AutocompleteFormField } from "common/Fields";
import { increments, getTotal, formatResourcesForSubmit } from "../utils";
import Empty from "common/Empty";

import {
  fetchMemberUsers,
  memberUsersSelector,
  getMemberUsersLoadingSelector,
} from "features/Users/usersSlice";
import {
  getMemberDataSelector,
  getDivisionDataSelector,
} from "features/Job/jobSlice";
import {
  fetchWorkAreas,
  workAreasSelectors,
  getAreasLoadingSelector,
} from "features/WorkAreas/workAreasSlice";

import { postEquipment } from "features/DailySheets/dailysheetsSlice";
import { categoriesSelectors } from "features/PriceList/priceListSlice";
// import { RadioFormField } from "common/Fields";
import { fetchDivisionSnapshot } from "features/Job/jobSlice";
import { checkTMMarkup } from "components/TMTerms/utils";
import TMTermsDailySheetMarkupComponent from "components/TMTerms/forms/components/TMTermsDailySheetMarkupComponent";
import Guard from "common/Guard"



const getIncrementValue = (increment, key) => {
  if (key === "w_price") return "wk";
  if (key === "m_price") return "mnth";
  if (key === "market") return "mrkt";

  return increments[increment] ? increments[increment] : increment;
};

const getPriceValue = (item, key) => {
  if (key === "market") return "";
  if (key && item[key]) return item[key];

  return item.price;
};

const getSubCategoryItems = (s, key) => {
  let items = [];
  toArray(s).forEach((sub) => {
    items = [
      ...items,
      ...toArray(sub.items)
        .filter((el) => {
          if (key && key !== "market") {
            return el[key] > 0;
          } else {
            return true;
          }
        })
        .map((item) => ({
          ...item,
          value: "",
          increment: getIncrementValue(item.increment, key),
          price: getPriceValue(item, key),
          sub_category_tid: sub.tid,
        })),
    ];
  });

  return items;
};

const formatResourcesForForm = (r, key) => {
  const resources = toArray(r);

  return resources.map((category) => {
    return {
      name: category.name,
      children: getSubCategoryItems(category.sub_categories, key),
    };
  });
};

// const getGrandTotal = (data) => {
//   let total = 0;
//   data.forEach((resource) => {
//     let t = getTotal(resource.data);
//     t *= resource.days;
//     total += t;
//   });

//   return total;
// };

const enumerateDaysBetweenDates = (startDate, endDate) => {
  const dates = [startDate.format("YYYY-MM-DD")];

  if (startDate && endDate && !startDate.isSame(endDate)) {
    if (startDate.isBefore(endDate)) {
      const currDate = startDate.clone().startOf("day");
      const lastDate = endDate.clone().startOf("day");

      while (currDate.add(1, "days").diff(lastDate) < 0) {
        dates.push(currDate.clone().format("YYYY-MM-DD"));
      }

      dates.push(endDate.format("YYYY-MM-DD"));
    }
  }

  return dates;
};

const Fields = ({
  values,
  name,
  isSubmitting,
  hidden,
  range,
  editablePrice,
}) => {
  const dispatch = useDispatch();
  const division = useSelector(getDivisionDataSelector);
  const member = useSelector(getMemberDataSelector);
  const users = useSelector(memberUsersSelector.selectAll);
  const loadingUsers = useSelector(getMemberUsersLoadingSelector);
  const areas = useSelector(workAreasSelectors.selectAll);
  const loadingAreas = useSelector(getAreasLoadingSelector);
  // const markup = checkTMMarkup(division?.field_tm_terms, "equipment");

  const getWorkAreas = useCallback(
    (query) => {
      return dispatch(
        fetchWorkAreas({
          id: division?.nid,
          params: {
            keywords: query,
          },
        })
      );
    },
    [division, dispatch]
  );

  const getUsers = useCallback(
    (query) => {
      return dispatch(
        fetchMemberUsers({
          id: member?.nid,
          params: {
            keywords: query,
          },
        })
      );
    },
    [member, dispatch]
  );

  const handleSearch = (e, value) => {
    if (value?.name.length > 0) {
      const item = document.querySelector(`[id*="${slugify(value.name)}"]`);
      if (item) {
        item.style.transition = "outline-color 0.25s";
        item.style.outlineSize = "5px";
        item.style.outlineStyle = "solid";
        item.style.outlineColor = "transparent";
        item.style.zIndex = "2";
        setTimeout(() => {
          item.style.outlineColor = "red";
        }, 500);

        setTimeout(() => {
          item.style.outlineColor = "transparent";
        }, 3000);
        item.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  };

  const getResourcesForAutocomplete = (data) => {
    let resources = [];

    data.forEach((res) => {
      const items = res.children.map((r) => ({
        ...r,
        name: r.name ? r.name : "Unknown",
      }));
      resources = [...resources, ...items];
    });

    return resources;
  };

  const hasResources = useMemo(() => {
    const resources = values[name]?.resources;
    if (resources) {
      for (let index = 0; index < resources.length; index++) {
        const res = resources[index];
        if (size(res.children) > 0) {
          return true;
        }
      }
    }

    return false;
  }, [values, name]);

  if (hidden) return null;
  return (
    <div>
      <Grid container spacing={1} className="flex-col grid-md:flex-row">
        <Grid item xxs={12}>
          <Typography variant="h6">
            {`${name} Total: ${formatMoney(getTotal(values[name]))}`}
          </Typography>
          {range && (
            <Typography variant="caption" color="textSecondary">
              This is the total for each day
            </Typography>
          )}
        </Grid>
        {range && (
          <Grid item xxs={12}>
            <DateRangeFormField
              name={`${name}.field_ie_date_range`}
              label="Date Range"
              hideKeyboardShortcutsPanel
              minimumNights={0}
              isOutsideRange={() => false}
            />
          </Grid>
        )}
        <Grid item xxs={12} sm={4}>
          <CheckboxFormField
            name={`${name}.field_ds_taxable`}
            label="Taxable"
          />
        </Grid>
        <Guard customOverrideName="canManageEntryVisibility">
          <Grid item xxs={12} sm={4}>
            <CheckboxFormField name={`${name}.field_visibility`} label="Do not share with provider" />
          </Grid>
        </Guard>
        <Grid item xxs={12} sm={4}>
          <TMTermsDailySheetMarkupComponent
            namePrefix={`${name}.`}
            isSubmitting={isSubmitting}
            values={values[name]}
            dsType="equipment"
          />
        </Grid>
        <Grid item xxs={12} sm={range ? 6 : 4}>
          <AutocompleteFormField
            name={`${name}.field_ds_user`}
            variant="filled"
            label="User"
            size="small"
            margin="normal"
            disabled={isSubmitting}
            options={toArray(users).map((user) => ({
              name: user.name,
              uid: user.uid,
            }))}
            loading={loadingUsers}
            fetchData={getUsers}
          />
        </Grid>
        {!range && (
          <Grid item xxs={12} sm={4}>
            <DateFormField
              name={`${name}.field_ds_date`}
              fullWidth
              disableToolbar
              variant="inline"
              format="MM/DD/YYYY"
              id="date"
              label="Date"
              margin="normal"
              inputVariant="filled"
              size="small"
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
        )}
        <Grid item xxs={12} sm={range ? 6 : 4}>
          <AutocompleteFormField
            name={`${name}.field_ds_work_area_allocation`}
            variant="filled"
            label="Allocate to Area"
            size="small"
            margin="normal"
            disabled={isSubmitting || !Boolean(size(areas))}
            options={toArray(areas).map((area) => ({
              name: area.title,
              nid: area.nid,
            }))}
            loading={loadingAreas}
            fetchData={getWorkAreas}
          />
        </Grid>
      </Grid>
      <Sticky
        scrollElement=".MuiDialogContent-root"
        stickyStyle={{ zIndex: 2, transform: "translateY(-32px)" }}
      >
        <div style={{ background: "white", height: "100%" }}>
          <Autocomplete
            id="resource-search"
            options={getResourcesForAutocomplete(values[name].resources)}
            autoComplete={false}
            autoHighlight
            onChange={(e, value) => handleSearch(e, value)}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label="Search"
                margin="normal"
                variant="filled"
              />
            )}
          />
        </div>
      </Sticky>
      <FieldArray
        name={`${name}.resources`}
        render={(arrayHelpers) =>
          hasResources ? (
            <div>
              {values[name].resources.map((category, i) => (
                <Grid container spacing={0} className="flex-col grid-md:flex-row">
                  {size(category.children) > 0 && (
                    <>
                      <Grid item xxs={12}>
                        <EquipmentBatchHeader>
                          <Typography variant="subtitle1">
                            {category.name}
                          </Typography>
                        </EquipmentBatchHeader>
                      </Grid>
                      <>
                        {category.children.map((item, x) => (
                          <Grid item xxs={12} sm={6} id={slugify(item.name)}>
                            <Grid container spacing={0}>
                              <Grid item xxs={editablePrice ? 8 : 10}>
                                <EquipmentBatchLabel>
                                  <Typography variant="caption">
                                    {item.name}
                                  </Typography>
                                </EquipmentBatchLabel>
                              </Grid>
                              <Grid item xxs={2}>
                                <Field
                                  name={`${name}.resources.${i}.children.${x}.value`}
                                >
                                  {({
                                    field, // { name, value, onChange, onBlur }
                                    form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                    meta,
                                  }) => (
                                    <EquipmentBatchField>
                                      <div className="increment">
                                        {increments[item.increment]
                                          ? increments[item.increment]
                                          : item.increment}
                                      </div>
                                      <input
                                        type="text"
                                        disabled={isSubmitting}
                                        {...field}
                                      />
                                      {meta.touched && meta.error && (
                                        <div className="error">
                                          {meta.error}
                                        </div>
                                      )}
                                    </EquipmentBatchField>
                                  )}
                                </Field>
                              </Grid>
                              {editablePrice && (
                                <Grid item xxs={2}>
                                  <Field
                                    name={`${name}.resources.${i}.children.${x}.price`}
                                  >
                                    {({
                                      field, // { name, value, onChange, onBlur }
                                      form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                      meta,
                                    }) => (
                                      <EquipmentBatchField>
                                        <div className="increment">Price</div>
                                        <input
                                          type="text"
                                          disabled={isSubmitting}
                                          {...field}
                                        />
                                        {meta.touched && meta.error && (
                                          <div className="error">
                                            {meta.error}
                                          </div>
                                        )}
                                      </EquipmentBatchField>
                                    )}
                                  </Field>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        ))}
                      </>
                    </>
                  )}
                </Grid>
              ))}
            </div>
          ) : (
            <Empty
              message={`No ${name} rates have been set in this price list`}
            >
              <GiArchiveResearch />
            </Empty>
          )
        }
      />
    </div>
  );
};

const CreateEquipment = ({users=[], ...props}) => {
  const dispatch = useDispatch();
  const division = useSelector(getDivisionDataSelector);
  const divisionProcessed = division?._processed ? division._processed : {};
  const userFields = useSelector((state) => state.auth.user.data);
  const allResources = useSelector(categoriesSelectors.selectAll);
  const divisionStatuses = useSelector((state) => state.taxonomies.jobDivisionStatus.entities)

  const [open, setOpen] = useState(false);
  const [progress, setProgress] = useState({
    total: 0,
    obtained: 0,
    show: false,
  });
  const markup = checkTMMarkup(division?.field_tm_terms, "equipment");

  const handleCreateEquipment = async (data, date, rate) => {
    const { resources, total, markup } = formatResourcesForSubmit(data);
    const params = {
      user: data.field_ds_user,
      date,
      work_area: data?.field_ds_work_area_allocation?.nid,
      resources,
      total,
      division: division.title,
      daily_sheet_nid: generateTempUUID(),
      rate,
      field_ds_taxable: data.field_ds_taxable,
      field_ds_markup: markup ? markup : null,
      field_ds_percent_markup: null,
      ...(typeof data?.field_visibility != 'undefined') && { field_visibility: data.field_visibility ? 'phx_client' : null }
    };
    if(data.markup_type === 'percent' && Number(data.field_ds_percent_markup) > 0){
      params.field_ds_percent_markup = Number(data.field_ds_percent_markup);
    }

    if (size(resources) > 0) {
      return dispatch(postEquipment({ id: division.nid, params }));
    } else {
      return false;
    }
  };

  const handleCreateDateRangeEquipment = async (
    data,
    setMessage,
    setProgress,
    rate
  ) => {
    const days = enumerateDaysBetweenDates(
      moment(data.field_ie_date_range.start),
      moment(data.field_ie_date_range.end)
    );
    for (let i = 0; i < size(days); i += 1) {
      try {
        const resultAction = await handleCreateEquipment(data, days[i], rate);
        unwrapResult(resultAction);
        setProgress((progress) => ({
          ...progress,
          obtained: progress.obtained + 1,
        }));
        if(props?.fromSummaryCard){
          dispatch(fetchDivisionSnapshot(division.nid));
        }
      } catch (err) {
        setMessage({ id: "negative", msg: err.message });
        setProgress({ total: 0, obtained: 0, show: false });
        break;
      }
    }
  };

  const handleCreateSingleEquipment = async (
    data,
    setMessage,
    setProgress,
    rate
  ) => {
    try {
      const resultAction = await handleCreateEquipment(
        data,
        moment(data.field_ds_date).format("YYYY-MM-DD"),
        rate
      );
      unwrapResult(resultAction);

      setProgress((progress) => ({
        ...progress,
        obtained: progress.obtained + 1,
      }));

      if(props?.fromSummaryCard){
        dispatch(fetchDivisionSnapshot(division.nid));
      }
      return resultAction;
    } catch (err) {
      setMessage({ id: "negative", msg: err.message });
      setProgress({ total: 0, obtained: 0, show: false });
      return false;
    }
  };

  const handleSubmit = async (
    data,
    setSubmitting,
    setMessage,
    successCallback,
    errorCallback,
    setFieldValue
  ) => {
    const dailyDays = data.daily.field_ie_date_range.days;
    const marketDays = data.market.field_ie_date_range.days;
    const total = dailyDays + marketDays + 2;
    setProgress({ total, obtained: 0, show: true });

    if (data.type === "daily") {
      await handleCreateDateRangeEquipment(
        data.daily,
        setMessage,
        setProgress,
        "Daily"
      );
    }
    // if (data.type === "market") {
    //   await handleCreateDateRangeEquipment(
    //     data.market,
    //     setMessage,
    //     setProgress,
    //     "Market Rate"
    //   );
    // }
    if (data.type === "weekly") {
      await handleCreateSingleEquipment(
        data.weekly,
        setMessage,
        setProgress,
        "Weekly"
      );
    }
    if (data.type === "monthly") {
      await handleCreateSingleEquipment(
        data.monthly,
        setMessage,
        setProgress,
        "Monthly"
      );
    }
    if(props?.handleRefresh){
      props.handleRefresh();
    }

    successCallback("Added equipment successfully.");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const initVals = {
    field_ds_taxable: true,
    field_ds_markup: markup && markup?.value ? markup.value : '',
    field_ds_percent_markup: markup && markup?.percent ? markup.percent : '',
    markup_type: markup ? (markup?.value ? 'flat' : 'percent') : '',
    field_ds_user:
      userFields.uid && userFields.name
        ? { uid: userFields.uid, name: userFields.name }
        : null,
    field_ds_work_area_allocation: null,
    ...(divisionProcessed?.ds_manage_entry_visibility) && { field_visibility:  isDivisionStatusPast(division, divisionStatuses, 'review')},
  }

  return (
    <>
      <ListItem
        button
        style={{ paddingLeft: "2rem" }}
        onClick={() => setOpen(true)}
        className={props?.className}
      >
        <ListItemIcon>
          <AiOutlineFileAdd />
        </ListItemIcon>
        <ListItemText primary="Equipment" />
      </ListItem>
      <DialogForm
        open={open}
        maxWidth="lg"
        title="Add Equipment"
        onClose={handleClose}
        validateOnChange={false}
        initialValues={{
          type: "daily",
          daily: {
            ...initVals,
            field_ie_date_range: {
              start: moment(),
              end: moment(),
              days: 1,
            },
            resources: formatResourcesForForm(allResources),
          },
        //  The following are depreciated
          weekly: {
            ...initVals,
            field_ds_date: moment(),
            resources: formatResourcesForForm(allResources, "w_price"),
          },
          monthly: {
            ...initVals,
            field_ds_date: moment(),
            resources: formatResourcesForForm(allResources, "m_price"),
          },
          market: {
            ...initVals,
            field_ie_date_range: {
              start: moment(),
              end: moment(),
              days: 1,
            },
            // resources: formatResourcesForForm(allResources, "market"),
            resources: [],
          },
        }}
        onSubmit={handleSubmit}
        progress={progress}
      >
        {({ values, errors, isSubmitting, setFieldValue }) => (
          <>
            <BatchContainer style={{ position: "relative" }}>
              {/* <div style={{ marginBottom: "2rem" }}>
                <Typography variant="h6">
                  Total:{" "}
                  {formatMoney(
                    getGrandTotal([
                      {
                        data: values.daily.resources,
                        days: values.daily.field_ie_date_range.days,
                      },
                      { data: values.weekly.resources, days: 1 },
                      { data: values.monthly.resources, days: 1 },
                      {
                        data: values.market.resources,
                        days: values.market.field_ie_date_range.days,
                      },
                    ])
                  )}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  This is the grand total for all categories
                </Typography>
              </div> */}
              {/* <div style={{ margin: "1rem 0" }}>
                <RadioFormField
                  name="type"
                  aria="type-radio"
                  label="Pricing"
                  disabled={isSubmitting}
                  style={{ flexDirection: "row" }}
                  options={[
                    { label: "Daily", value: "daily" },
                    { label: "Weekly", value: "weekly" },
                    { label: "Monthly", value: "monthly" },
                  ]}
                  orientation="horizontal"
                />
              </div> */}
              <Fields
                values={values}
                name="daily"
                isSubmitting={isSubmitting}
                hidden={values.type !== "daily"}
                range
              />
              {/*<Fields
                values={values}
                name="weekly"
                isSubmitting={isSubmitting}
                hidden={values.type !== "weekly"}
              />
              <Fields
                values={values}
                name="monthly"
                isSubmitting={isSubmitting}
                hidden={values.type !== "monthly"}
              /> */}
              {/* <Fields
                values={values}
                name="market"
                isSubmitting={isSubmitting}
                hidden={values.type !== "market"}
                range
                editablePrice
              /> */}
            </BatchContainer>
          </>
        )}
      </DialogForm>
    </>
  );
};

CreateEquipment.propTypes = {};

export default CreateEquipment;
