import React, {  } from "react";
import { useSelector } from "react-redux";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { size } from 'lodash';
import TimeClockDialog from './TimeClockDialog';
import PunchClockIcon from '@mui/icons-material/PunchClock';
import { getDivisionDataSelector, } from "features/Job/jobSlice";

const TimeClockMenuItem = ({menuProps, iconOnly, onClose, defaultUser}) => {
  const division = useSelector(getDivisionDataSelector);
  const timeClockActive = (size(division) ? division?._processed?.time_clock_active : false);

  return (
    <TimeClockDialog
      divisionHash={division.field_public_hash}
      title={
        <>
        <PunchClockIcon /> Time Clock
        </>
      }
      onClose={onClose}
      disabled={!timeClockActive}
      defaultUser={defaultUser}
    >
      {!iconOnly ? (
        timeClockActive ? (
          <ListItem button
            style={{ paddingLeft: "3rem" }}
            {...menuProps}
          >
            <ListItemIcon>
              <PunchClockIcon />
            </ListItemIcon>
            <ListItemText primary="Time Clock" />
          </ListItem>
        ) : (
          <></>
        )
      ) : (
        <Tooltip title={`Time Clock ${!timeClockActive ? ' closed' : ''}`}>
          <div {...menuProps}>
            <PunchClockIcon className={!timeClockActive ? ' disabled' : ''}/>
          </div>
        </Tooltip>
      )}
    </TimeClockDialog>
  );
};

TimeClockMenuItem.propTypes = {};

export default TimeClockMenuItem;
