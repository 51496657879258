import React, { useState, useEffect } from "react";
import {
  Tooltip,
  useMediaQuery,
  MenuItem,
  ListItemIcon,
  Typography,
  IconButton,
} from "@mui/material";
import Dialog from "common/Dialog";
import { ReactComponent as TimeCardIconRaw } from "assets/images/icons/icon-time-card.svg";
import styled from "styled-components";
import TimeClock from "./TimeClock";

const TimeCardIcon = styled(TimeCardIconRaw)`
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
`;

const TimeClockDialog = ({
  divisionHash,
  serviceHash,
  title,
  fullButton,
  onClose,
  manualOpen,
  disabled=false,
  defaultUser,
  empty=false,
  showDivisionInfo=false,
  children
}) => {
  const [open, setOpen] = useState(false);
  const [newServiceHash, setNewServiceHash] = useState(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const _title = (
    title ? (title) : (
      <><TimeCardIcon /> Time Card</>
    )
  );

  useEffect(() => {
    if(manualOpen){
      setOpen(true);
    }
  }, [manualOpen])

  const handleOpen = () => {
    if(!disabled){
      setOpen(true);
    }
  }

  const handleClose = () => {
    if(onClose){
      onClose();
    }
    if(!serviceHash){
      setNewServiceHash(null);
    }
    setOpen(false)
  }

  return (
    <>
    {!empty &&
      (!children ? (
        isMobile || fullButton ? (
          <MenuItem onClick={handleOpen}>
              <>
              <ListItemIcon>
                <TimeCardIcon />
              </ListItemIcon>
              <Typography variant="inherit">Time Card</Typography>
              </>
          </MenuItem>
        ) : (
          <Tooltip title="Time Card">
            <IconButton
              size="small"
              onClick={handleOpen}
              style={{ marginRight: "0.5rem" }}
            >
              <TimeCardIcon />
            </IconButton>
          </Tooltip>
        )
      ) : (
        React.cloneElement(children, {
          onClick: handleOpen,
        })
      ))
    }
    <Dialog
      open={open}
      onClose={handleClose}
      title={_title}
      paperProps={{className: "time-clock dialog"}}
    >
      <TimeClock
        divisionHash={divisionHash}
        serviceHash={newServiceHash ? newServiceHash : serviceHash}
        setServiceHash={setNewServiceHash}
        newTimeCard={!Boolean(serviceHash)}
        closeDialog={handleClose}
        defaultUser={defaultUser}
        timeCardOnly
        showDivisionInfo={showDivisionInfo}
      />
    </Dialog>
    </>
  );
};

TimeClockDialog.propTypes = {};

export default TimeClockDialog;
